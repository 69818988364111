import { Component, OnInit, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '@services/spinner.service';

import { AuthService } from 'AuthService';

import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router';
import { ModalService } from 'ModalService';
import { RoutingState } from 'RoutingState';

import { locale, loadMessages, formatDate } from 'devextreme/localization';
//import 'devextreme-intl';
//import * as deMessages from 'devextreme/localization/messages/de.json';

//import { locale, loadMessages } from 'devextreme/localization';
//import 'devextreme-intl';
// var messagesDe = require('devextreme/localization/messages/de.json');
import deMessages from 'devextreme/localization/messages/de.json';

//locale(navigator.language);


import {
    trigger,
    animate,
    transition,
    style
} from '@angular/animations';


import NumberBox from 'devextreme/ui/number_box';
import TagBox from 'devextreme/ui/tag_box';
import SelectBox from 'devextreme/ui/select_box';
import TextBox from 'devextreme/ui/text_box';
import TextArea from 'devextreme/ui/text_area';
import DateBox from 'devextreme/ui/date_box';
import { AjaxService } from '../../services/ajax.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('mainFadeAnimation', [
            transition('* => fadeIn', [
                style({ opacity: 0 }),
                animate(500, style({ opacity: 1 }))
            ]),
            transition('* => fadeOut', [
                animate(500, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppComponent implements OnInit {

    isLoggedIn: boolean = false;
    isExtern: boolean = false;
    subscription: Subscription;
    loading = false;

    stateMain: string = 'fadeIn';

    constructor(private routingState: RoutingState, public authService: AuthService, private router: Router, private spinnerService: SpinnerService, private modalService: ModalService, private zone: NgZone, private ajaxService: AjaxService) {

        this.ajaxService.init();

        loadMessages(deMessages);
        locale('de');
        this.routingState.loadRouting();
    }

    ngOnInit(): void {

        this.setGlobalConfig();

        this.spinnerService.loadingState.subscribe(x => {
            this.zone.run(() => {
                this.stateMain = x ? 'fadeOut' : 'fadeIn';
                this.loading = x;
            });
        });

        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });

        this.subscription = this.authService.getLoginState().subscribe(state => {
            if (state) {
                setTimeout(() => {
                    this.isExtern = this.authService.isExtern();
                    this.isLoggedIn = true;
                }, 0);
            }
            else {
                setTimeout(() => {
                    this.isExtern = false;
                    this.isLoggedIn = false;
                }, 0);
            }
        });
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.spinnerService.show();
            //this.loading = true;
        }
        if (event instanceof NavigationEnd) {
            //this.loading = false;
            this.spinnerService.hide();
        }

        if (event instanceof NavigationCancel) {
            //this.loading = false;
            this.spinnerService.hide();
        }
        if (event instanceof NavigationError) {
            //this.loading = false;
            this.spinnerService.hide();
        }
    }

    feedback_toggle_visibility() {
        this.modalService.open('modal-feedback', null);
    }

    setGlobalConfig() {
        NumberBox.defaultOptions({
            options: {
                showClearButton: true,
                min: 0,
                max: 999999,
                showSpinButtons: true,
                step: 1
            }
        });

        TagBox.defaultOptions({
            options: {
                showClearButton: true,
                applyValueMode: 'useButtons'
            }
        });

        SelectBox.defaultOptions({
            options: {
                showClearButton: true,
            }
        });

        TextBox.defaultOptions({
            options: {
                showClearButton: true,
                maxLength: 3999
            }
        });

        TextArea.defaultOptions({
            options: {
                showClearButton: true,
                height: 200,
                maxLength: 3999
            }
        });

        DateBox.defaultOptions({
            options: {
                showClearButton: true,
                displayFormat: 'dd.MM.yyyy',
                type: 'date',
                pickerType: 'calendar',
                min: '1900/1/1',
                max: '2099/12/31',
                dateOutOfRangeMessage: 'Jahr muss zwischen 1900 und 2099 liegen!',
                placeholder: 'Datum',
                useMaskBehavior: true,
                dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                value: new Date(),
                //onInitialized: function(e) {
                //    e.component.option('value', new Date());
                //}
            }
        });
    }
}
