
<div *ngIf="!loading" class="container-fluid">
    <div class="row" style="padding-top:100px;">
        <div class="col-md-4 col-md-offset-4">
            <form role="form" id="pw_recovery_form" [formGroup]="form" autocomplete='off'>
                <div class="panel panel-primary">
                    <div class="panel-heading">Passwort vergessen</div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <labeled-input [id]="'pw_recovery_username'" formControlName="username" [control]="form.controls.username" (keyup.enter)="onKeyupEnter()" [label]="'Benutzername'"></labeled-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <labeled-input [id]="'pw_recovery_email'" formControlName="email" [control]="form.controls.email" (keyup.enter)="onKeyupEnter()" [label]="'Email'" [type]="'email'"></labeled-input>
                                </div>
                            </div>
                            <div class="row text-center" style="margin-top:20px;">
                                <re-captcha #recaptcha id="pw_recovery_recaptcha" style="display:inline-block;" (resolved)="resolved($event)"></re-captcha>
                            </div>
                            <div class="row text-center" style="margin-top:20px;">
                                <ul *ngIf="errMsg" class="validation-errors" style="list-style:none; padding:0;">
                                    <li style="color: red"><strong>{{errMsg}}</strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer">
                        <button type="button" id="pw_recovery_submit" class="btn btn-primary" style="margin-right:10px;" (click)="onSubmit()"><span class="glyphicon glyphicon-save"> Absenden</span></button>
                        <button type="button" id="pw_recovery_cancel" class="btn btn-danger" style="margin-right:10px;" (click)="onClickAbbruch()"><span class="glyphicon glyphicon-triangle-left"> Abbruch</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
