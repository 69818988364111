<div class="app-modal-container">
    <div class="row" style="margin:0!important;">
        <div class="col-md-4 col-centered">
            <div class="app-modal-body">
                <div class="panel panel-primary">
                    <div class="panel-heading">Sicherheits-Abfrage</div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <label>{{msg}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer">
                        <button type="button" style="margin-right:5px;" class="btn btn-success" (click)="onClickJa()"><span class="glyphicon glyphicon-save"> Ja</span></button>
                        <button type="button" style="margin-right:5px;" class="btn btn-danger" (click)="onClickNein()"><span class="glyphicon glyphicon-remove"> Nein</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-modal-background"></div>