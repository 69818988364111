<div class="container-fluid">
    <div class="row" style="padding-top:100px;">
        <div class="col-md-4 col-md-offset-4">
            <form role="form" [formGroup]="form" id="login_form" autocomplete='off'>
                <div class="panel panel-primary">
                    <div class="panel-heading">Dabea</div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <labeled-input [id]="'login_username'" formControlName="username" [control]="form.controls.username" (keyup.enter)="onKeyupEnter()" [label]="'Benutzer'"></labeled-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <labeled-input [id]="'login_password'" formControlName="password" [control]="form.controls.password" (keyup.enter)="onKeyupEnter()" [label]="'Passwort'" [type]="'password'"></labeled-input>
                                </div>
                            </div>
                            <div *ngIf="showCaptcha" class="row text-center" style="margin-top:20px;">
                                <re-captcha #recaptcha style="display:inline-block;" (resolved)="resolved($event)"></re-captcha>
                            </div>
                            <div class="row text-center" style="margin-top:20px;">
                                <ul *ngIf="errMsg" class="validation-errors" style="list-style:none; padding:0;">
                                    <li style="color: red"><strong>{{errMsg}}</strong></li>
                                </ul>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="button" id="login_submit" (click)="onSubmit()" style="margin-top: 10px" class="form-control btn btn-primary">Anmelden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <span style="cursor:pointer;" class="col-md-12 text-center"><a (click)="onTraegerRegisterClick()"><i class="glyphicon glyphicon-log-in"></i> Träger registrieren</a></span>
            </div>
            <div class="row">
                <span style="cursor:pointer;" class="col-md-12 text-center"><a (click)="onPasswordRecoveryClick()"><i class="glyphicon glyphicon-remove-circle"></i> Passwort vergessen</a></span>
            </div>
        </div>
    </div>
</div>

