<div class="app-modal-container">
    <div class="row" style="margin:0!important;">
        <div class="col-md-6 col-centered">
            <div class="app-modal-body app-modal-body-error">
                <div class="panel panel-danger">
                    <div class="panel-heading">Problem</div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div *ngIf="error">
                                    <div style="width:100%; overflow: auto; background-color: antiquewhite; padding: 10px;">
                                        <h2 class="text-danger" [innerHTML]="message" style="white-space: nowrap;"></h2>
                                    </div>
                                    <p> </p>
                                    <br />

                                    <div class="panel-group" *ngIf="exceptionDetails">
                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h4 class="panel-title">
                                                    <a data-toggle="collapse" href="#collapse1">JSON</a>
                                                </h4>
                                            </div>
                                            <div id="collapse1" class="panel-collapse collapse">
                                                <!--<pre style="height:200px;">{{error | json}}</pre>-->
                                                <pre style="height:200px;">{{errorJsonString}}</pre>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h4 class="panel-title">
                                                    <a data-toggle="collapse" href="#collapse2">STACK</a>
                                                </h4>
                                            </div>
                                            <div id="collapse2" class="panel-collapse collapse">
                                                <pre style="height:200px;" [innerHTML]="stack"></pre>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="panel-footer">
                        <!--<button type="button" class="btn btn-success" (click)="refresh();">Refresh</button>-->
                        <button type="button" class="btn btn-warning" (click)="OnSendFeedback();">Problem an Entwickler senden</button>
                        <button type="button" class="btn btn-danger pull-right" (click)="closeModal('modal-error');">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-modal-background"></div>
