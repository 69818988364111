import { Component, OnInit, Input } from '@angular/core';
import { IWiedervorlageViewModel } from '../home-wiedervorlage.model';
import { AjaxService } from 'AjaxService';
import { IDefaultSelectBoxViewModel } from '../../shared/models/default-selectBox.model';
import { Router } from '@angular/router';
import { ModalService } from '../../../services/modal.service';
import { EntityArt } from '@enums/entityArt.enum';

@Component({
    selector: 'cz-home-wiedervorlage',
    templateUrl: './home-wiedervorlage.component.html',
    styleUrls: ['home-wiedervorlage.component.css']
})

export class HomeWiedervorlageComponent implements OnInit {
    constructor(private modalService: ModalService, private _ajaxService: AjaxService, private router: Router) {
      this.remove = this.remove.bind(this);

        this._ajaxService.get<IDefaultSelectBoxViewModel[]>('api/Benutzer/GetAll').subscribe(response => {
            this.mitarbeiter = response.map(v => {
                return { id: v.id, name: v.name };
            });
        });
        this._ajaxService.get<IDefaultSelectBoxViewModel[]>('api/Wiedervorlagen/Gruende').subscribe(response => {
            this.gruende = response.map(g => {
                return { id: g.id, name: g.name };
            });
        });
    }

    ngOnInit() {
        this.updateViewData();
    }

    _userId: number;
    get userId(): number {
        return this._userId;
    }

    @Input()
    set userId(value: number) {
        this._userId = (+value);
    }

    items: IWiedervorlageViewModel[];
    mitarbeiter: Array<any>;
    gruende: Array<any>;

    public updateViewData() {
        this._ajaxService.get<IWiedervorlageViewModel[]>('api/Wiedervorlagen/GetByUser?selectedUserId=' + this.userId).subscribe(response => {
            this.items = response;
        });
    }

    onClickOpen(wiedervorlage: IWiedervorlageViewModel) {
        if (EntityArt[wiedervorlage.entityArt] === 'Traeger') {
            this.router.navigate(['/traeger/edit', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'HauptEinrichtung') {
            this.router.navigate(['/hauptEinrichtung/edit', wiedervorlage.ownerId, wiedervorlage.parentId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'UnterEinrichtung') {
            this.router.navigate(['/hauptEinrichtung/editUnterEinrichtung', wiedervorlage.ownerId, wiedervorlage.parentId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldungenEoE') {
            this.router.navigate(['/meldungen/eoe/edit', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenTraegerAnschrift') {
            this.router.navigate(['/meldungen/meldepflichten/traegeranschrift', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenEinrichtungSchliessung') {
            this.router.navigate(['/meldungen/meldepflichten/einrichtungSchliessung', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenKontakte') {
            this.router.navigate(['/meldungen/meldepflichten/kontakte', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenKonzeption') {
            this.router.navigate(['/meldungen/meldepflichten/konzeption', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenLeitung') {
            this.router.navigate(['/meldungen/meldepflichten/leitung', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldepflichtenBetreuung') {
            this.router.navigate(['/meldungen/meldepflichten/betreuung', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'AntragBe') {
            if (wiedervorlage.type === 1) {
                this.router.navigate(['/antragBe/erst', wiedervorlage.ownerId]);
            }
            else if (wiedervorlage.type === 2) {
                this.router.navigate(['/antragBe/veraenderung', wiedervorlage.ownerId]);
            }
            else if (wiedervorlage.type === 3) {
                this.router.navigate(['/antragBe/erweiterung', wiedervorlage.ownerId]);
            }
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'MeldungenJaehrlich') {
            this.router.navigate(['/meldungen/jaehrlich/edit', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'Antrag_Ueberbelegung') {
            this.router.navigate(['/ueberbelegung/edit', wiedervorlage.ownerId]);
        }
        else if (EntityArt[wiedervorlage.entityArt] === 'Antrag_Anerkennung') {
            this.router.navigate(['/anrechnung/edit', wiedervorlage.ownerId]);
        }
    }

    handleOnRowPrepared(e) {
        if (e.rowType === 'data') {
            var result = this.getDateColor(e.data.termin);
            e.rowElement.style.color = result.color;
            e.rowElement.style.background = result.background;
        }
    }

    getDateColor(date: Date) {
        var result = {
            color: '',
            background: ''
        };

        if (new Date(date).toLocaleDateString() < this.nowDate.toLocaleDateString()) {
            result.color = 'red';
        }

        if (new Date(date).toLocaleDateString() === this.nowDate.toLocaleDateString()) {
            result.background = 'green';
        }

        return result;
    }

    _nowDate: Date;
    get nowDate(): Date {
        if (!this._nowDate) {
            var d = new Date();
            d.setHours(0, 0, 0, 0);
            this._nowDate = d;
        }

        return this._nowDate;
    }

    save(e) {
        var wiedervorlage = this.setObjToSave(e.key, e.newData);
        this._ajaxService.post('api/Wiedervorlagen/Save', wiedervorlage).toPromise().then(response => {
            this.updateViewData();
        });
    }

    remove(e) {
        var sub1 = this.modalService.confirm('Wiedervorlage wirklich erledigt?').subscribe(response => {
            sub1.unsubscribe();
            if (response) {
                this._ajaxService.post('api/Wiedervorlagen/Remove', e.row.data.id).toPromise().then(response => {
                    this.updateViewData();
                });
            }
        });
    }

    private setObjToSave(data: IWiedervorlageViewModel, values): any {
        var v = Object.keys(values);
        for (let item of v) {
            if (data.hasOwnProperty(item)) {
                data[item] = values[item];
            }
        }
        return data;
    }

    allowUpdating(e) {
        return e.row.data.canEdit;
    }

    isErledigtButtonVisible(e){
      return e.row.data.canEdit;
    }
}
