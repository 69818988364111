<nav class="navbar navbar-inverse navbar-fixed-top">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav">

        <li>
          <a [routerLinkActive]="['active']" [routerLink]="['/home']">
            <i class="fa fa-home fa-fw"></i>Dabea ({{version}})
          </a>
        </li>

        <li class="dropdown" userRoleNeeded_hidden="Traeger_Lesen,Traeger_Schreiben,Traeger_Registrierung_Lesen,Traeger_Registrierung_Schreiben">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            &nbsp;Träger <span title="Neue Träger-Registrierungen" *ngIf="(counts.traegerRegCount + counts.hasNewSelbstauskunft) > 0" class='badge czBadged'>{{counts.traegerRegCount + counts.hasNewSelbstauskunft}}</span> <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li userRoleNeeded_hidden="Traeger_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/traeger']">
                <i class="fa fa-search fa-fw"></i>Träger suchen
              </a>
            </li>
            <li userRoleNeeded_hidden="Traeger_Schreiben">
              <a [routerLinkActive]="['active']" [routerLink]="['/traeger/edit',0]">
                <i class="fa fa-plus fa-fw"></i>Träger anlegen
              </a>
            </li>
            <li userRoleNeeded_hidden="Traeger_Registrierung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/traegerRegisterBearbeiten']">
                <i class="fa fa-id-card-o fa-fw"></i>Träger-Registrierungen&nbsp;&nbsp;<span title="Neue Träger-Registrierungen" *ngIf="counts.traegerRegCount>0" class='badge czBadged '>{{counts.traegerRegCount}}</span>
              </a>
            </li>
            <li userRoleNeeded_hidden="Traeger_Registrierung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/selbstauskunft']">
                <i class="fa fa-handshake-o fa-fw"></i>Träger-Selbstauskünfte&nbsp;&nbsp;<span title="Neue Träger-Selbstauskünfte" *ngIf="counts.hasNewSelbstauskunft>0" class='badge czBadged '>{{counts.hasNewSelbstauskunft}}</span>
              </a>
            </li>
          </ul>
        </li>

        <li class="dropdown" userRoleNeeded_hidden="Einrichtung_Lesen,Einrichtung_Schreiben,AntragBE_Lesen,AntragBE_Schreiben,Ueberbelegung_Lesen,Ueberbelegung_Schreiben,Anrechnung_Lesen, Anrechnung_Schreiben">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="False">
            &nbsp;Haupteinrichtung <span title="Neue Betriebserlaubnis-Anträge" *ngIf="(counts.antragBeCount + counts.antragUeberbelegungCount + counts.antragAnrechnungCount)>0" class='badge czBadged'>{{counts.antragBeCount + counts.antragUeberbelegungCount + counts.antragAnrechnungCount}}</span> <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li userRoleNeeded_hidden="Einrichtung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/hauptEinrichtung']">
                <i class="fa fa-search fa-fw"></i>Haupteinrichtung suchen
              </a>
            </li>
            <li userRoleNeeded_hidden="Einrichtung_Schreiben">
              <a [routerLinkActive]="['active']" [routerLink]="['/hauptEinrichtung/edit',0,0]">
                <i class="fa fa-plus fa-fw"></i>Haupteinrichtung anlegen
              </a>
            </li>
            <li userRoleNeeded_hidden="AntragBE_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/antragBe']">
                <i class="fa fa-id-card-o fa-fw"></i>Anträge: Betriebserlaubnis&nbsp;&nbsp;<span title="Neue Anträge auf Betriebserlaubnis" *ngIf="counts.antragBeCount>0" class='badge czBadged '>{{counts.antragBeCount}}</span>
              </a>
            </li>
            <li userRoleNeeded_hidden="Ueberbelegung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/ueberbelegung']">
                <i class="fa fa-id-card-o fa-fw"></i>Anträge: Überbelegung&nbsp;&nbsp;<span title="Neue Anträge auf Überbelegung" *ngIf="counts.antragUeberbelegungCount>0" class='badge czBadged '>{{counts.antragUeberbelegungCount}}</span>
              </a>
            </li>
            <li userRoleNeeded_hidden="Anrechnung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/anrechnung']">
                <i class="fa fa-id-card-o fa-fw"></i>Anträge: Anrechnung Personen&nbsp;&nbsp;<span title="Neue Anträge auf Anrechnung Personen" *ngIf="counts.antragAnrechnungCount>0" class='badge czBadged '>{{counts.antragAnrechnungCount}}</span>
              </a>
            </li>

          </ul>
        </li>

        <li class="dropdown" userRoleNeeded_hidden="Meldungen_Lesen,Meldungen_Schreiben,Meldepflichten_Lesen,Meldepflichten_Schreiben">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            &nbsp;Meldungen <span title="Neue Meldungen" *ngIf="(counts.meldungenEoECount + counts.meldepflichtenCount + counts.meldungenJaehrlichCount)>0" class='badge czBadged '>{{(counts.meldungenEoECount + counts.meldepflichtenCount + counts.meldungenJaehrlichCount)}}</span> <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li userRoleNeeded_hidden="Meldungen_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/meldungen/eoe']">
                <i class="fa fa-id-card-o fa-fw"></i>Meldungen EoE&nbsp;&nbsp;<span title="Neue Meldungen-EoE" *ngIf="counts.meldungenEoECount>0" class='badge czBadged '>{{counts.meldungenEoECount}}</span>
              </a>
            </li>
            <li userRoleNeeded_hidden="Meldepflichten_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/meldungen/meldepflichten']">
                <i class="fa fa-id-card-o fa-fw"></i>Meldepflichten&nbsp;&nbsp;<span title="Neue Meldepflichten" *ngIf="counts.meldepflichtenCount>0" class='badge czBadged '>{{counts.meldepflichtenCount}}</span>
              </a>
            </li>
            <li userRoleNeeded_hidden="Meldepflichten_Lesen,Meldepflichten_Schreiben">
              <a [routerLinkActive]="['active']" [routerLink]="['/meldungen/jaehrlich']">
                <i class="fa fa-id-card-o fa-fw"></i>Jährliche Meldungen&nbsp;&nbsp;<span title="Neue jährliche Meldungen" *ngIf="counts.meldungenJaehrlichCount>0" class='badge czBadged '>{{counts.meldungenJaehrlichCount}}</span>
              </a>
            </li>
          </ul>
        </li>


        <li userRoleNeeded_hidden="Traeger_Lesen,Einrichtung_Lesen,Meldungen_Lesen, Meldepflichten_Lesen" class="dropdown">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            Recherche  <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li userRoleNeeded_hidden="Traeger_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/recherche/traeger']">
                <i class="fa fa-registered fa-fw"></i>Recherche Träger
              </a>
            </li>
            <li userRoleNeeded_hidden="Einrichtung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/recherche/einrichtung']">
                <i class="fa fa-registered fa-fw"></i>Recherche Einrichtungen
              </a>
            </li>
            <li userRoleNeeded_hidden="Einrichtung_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/recherche/personal']">
                <i class="fa fa-registered fa-fw"></i>Recherche Personal
              </a>
            </li>
            <li userRoleNeeded_hidden="Meldungen_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/recherche/eoe']">
                <i class="fa fa-registered fa-fw"></i>Recherche Meldungen-EoE
              </a>
            </li>
            <li userRoleNeeded_hidden="Meldepflichten_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/recherche/meldungenJaehrlich']">
                <i class="fa fa-registered fa-fw"></i>Recherche jährliche Meldungen
              </a>
            </li>
          </ul>
        </li>

        <li userRoleNeeded_hidden="Katalog_Lesen,Katalog_Schreiben" class="dropdown">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            Kataloge  <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/landkreise']">
                <i class="fa fa-globe fa-fw"></i>Landkreise verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/traegergruppen']">
                <i class="fa fa-object-group fa-fw"></i>Trägergruppen verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/verbaende']">
                <i class="fa fa-sitemap fa-fw"></i>Verbände verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/kontaktTypen']">
                <i class="fa fa-tags fa-fw"></i>Kontakttypen verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/rechtsform']">
                <i class="fa fa-paragraph fa-fw"></i>Rechtsformen verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/verwaltungsgerichte']">
                <i class="fa fa-university fa-fw"></i>Verwaltungsgerichte verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/angebotsGruppen']">
                <i class="fa fa-cart-arrow-down fa-fw"></i>Angebotsgruppen verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/ausbildung']">
                <i class="fa fa-address-card-o fa-fw"></i>Ausbildung verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/taetigkeit']">
                <i class="fa fa-address-card fa-fw"></i>Tätigkeiten verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/gruende']">
                <i class="fa fa-comment fa-fw"></i>Wiedervorlagen Gründe verwalten
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/eoeKatalog']">
                <i class="fa fa-map fa-fw"></i>Ereignisse und Entwicklungen verwalten
              </a>
            </li>
            <!-- <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/rechtsGrundlagenUnterbringung']">
                <i class="fa fa-map fa-fw"></i>Rechtsgrundlagen Unterbringung verwalten
              </a>
            </li> -->
          </ul>
        </li>

        <li class="dropdown" userRoleNeeded_hidden="Globale_Vorlagen_Lesen,Globale_Vorlagen_Schreiben,Vorlagen_Lesen,Vorlagen_Schreiben,Benutzer_MBJS_Lesen,Benutzer_MBJS_Schreiben,Benutzer_Traeger_Lesen,Benutzer_Traeger_Schreiben,Zugriffsrollen_Lesen,Zugriffsrollen_Schreiben">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            Optionen  <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li userRoleNeeded_hidden="Benutzer_MBJS_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/benutzer/bearbeiten']">
                <i class="fa fa-users fa-fw"></i>Benutzer MBJS
              </a>
            </li>
            <li userRoleNeeded_hidden="Benutzer_Traeger_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/benutzer-extern/bearbeiten']">
                <i class="fa fa-users fa-fw"></i>Benutzer Träger
              </a>
            </li>
            <li userRoleNeeded_hidden="Administrator">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/infos/bearbeiten']">
                <i class="fa fa-info-circle fa-fw"></i>Infos verwalten
              </a>
            </li>
            <li userRoleNeeded_hidden="Vorlagen_Lesen,Globale_Vorlagen_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/vorlagenVerwaltung']">
                <i class="fa fa-book fa-fw"></i>Vorlagenverwaltung
              </a>
            </li>
            <li userRoleNeeded_hidden="Zugriffsrollen_Lesen">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/zugriffsrollen']">
                <i class="fa fa-ban fa-fw"></i>Zugriffsrollen
              </a>
            </li>
            <li userRoleNeeded_hidden="Administrator">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/passwortrichtlinie']">
                <i class="fa fa-ban fa-fw"></i>Passwortrichtlinie
              </a>
            </li>
            <li userRoleNeeded_hidden="Administrator">
              <a [routerLinkActive]="['active']" [routerLink]="['/options/stichtag/bearbeiten']">
                <i class="fa fa-ban fa-fw"></i>Stichtagsverwaltung
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a [routerLinkActive]="['active']" [routerLink]="[]" (click)="onClickHelp($event)">
            <i class="fa fa-question-circle-o fa-fw"></i>Handbuch
          </a>
        </li>

      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            {{userName}}  <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/options/benutzer/profil']">
                <i class="fa fa-user fa-fw"></i>Profil
              </a>
            </li>
            <li>
              <a (click)="onClickLogout()" style="cursor:pointer;">
                <span class='glyphicon glyphicon-log-out'></span> Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<dx-popup class="popup"
          *ngIf="true"
          [showTitle]="true"
          title="Hilfe"
          [height]="'90%'"
          [width]="'80%'"
          [dragEnabled]="false"
          [closeOnOutsideClick]="false"
          (onHidden)="onHiddenPopup($event)"
          [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <cz-document-viewer *ngIf="popupVisible" [pdfUrl]="pdfSrc"></cz-document-viewer>
  </div>

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{
            text: 'Download',
            onClick: download
        }">
  </dxi-toolbar-item>

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{
            text: 'Schließen',
            onClick: hidePopup
        }">
  </dxi-toolbar-item>

</dx-popup>
