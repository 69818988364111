
<div class="loading-overlay" *ngIf="loading">
    <div class="animationload">
        <div class="osahanloading">
        </div>
        <h1 class="startMessage">Bitte warten...</h1>
    </div>
</div>

<div style="height:100%">
    <div *ngIf="isLoggedIn">
        <nav-menu *ngIf="!isExtern"></nav-menu>
        <nav-menu-extern *ngIf="isExtern"></nav-menu-extern>
    </div>
    <button *ngIf="isLoggedIn && !isExtern" class="feedback-button" (click)="feedback_toggle_visibility()">Feedback</button>

    <div class='body-content' style="margin-top: 50px; padding-top:20px; height: 100%;" [@mainFadeAnimation]="stateMain">
        <router-outlet></router-outlet>
    </div>
</div>
<app-error-modal id="modal-error"></app-error-modal>
<app-feedback-modal id="modal-feedback"></app-feedback-modal>
<app-dialogJaNein-modal id="modal-dialogJaNein"></app-dialogJaNein-modal>
