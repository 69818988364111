import { Component, OnInit, Input } from '@angular/core';
import { AjaxService } from 'AjaxService';

interface IHomeInfoViewModel {
    id: number;
    bezeichnung: string;
    beschreibung: string;
    wichtig: boolean;
    erstellt: string;
    benutzer: string;
}

@Component({
    selector: 'cz-home-info',
    templateUrl: './home-info.component.html'
})

export class HomeInfoComponent implements OnInit {

    constructor(private _ajaxService: AjaxService) {

    }

    ngOnInit() {
        this.updateViewData();
    }

    items: IHomeInfoViewModel[];

    public updateViewData() {
        this._ajaxService.get<IHomeInfoViewModel[]>('api/Home/GetInfos').subscribe(response => {
            this.items = response;
        });
    }
}
