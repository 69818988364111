import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable ,  throwError as _throw, BehaviorSubject } from 'rxjs';
import { SpinnerService } from '@services/spinner.service';

interface IConfig {
    exceptionDetails: boolean;
}


@Injectable()
export class AjaxService {
    debug: boolean = false;
    //config: IConfig;
    config = new BehaviorSubject<IConfig>({} as IConfig);

    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private spinnerService: SpinnerService) {
        this.log('AjaxService init!');
    }

    public init() {
        this.get<IConfig>('api/Account/GetConfig').subscribe(c => {
            this.config.next(c);
        }, err => {
            throw err;
        });
    }

    public blobToArrayBuffer(blob, cb) {
        var fileReader = new FileReader();
        fileReader.onload = function(event: any) {
            if (cb) {
                cb(event.target.result);
            }
        };
        fileReader.readAsArrayBuffer(blob);
    }

    public downloadResource(url: string): Promise<Blob> {
        let options = this.getRequestHeaders({ responseType: 'blob' as 'json' });
        let file = this.http.get<Blob>(url, options).toPromise();
        return file;
    }

    public upload<T>(url: string, input: FormData, cb: (data: T) => void , errCb: (err: any) => void = null) {
        var _headers = this.getRequestHeaders({ contentType: false });
        var uploadReq = new HttpRequest('POST', url, input, {
            reportProgress: false,
            headers: _headers.headers
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.Response) {
                var response = event.body as T;
                if (cb) {
                    cb(response);
                }
            }
        }, err => {
            if (errCb) {
                errCb(err);
            }
            else {
                this.spinnerService.hide();
                throw new Error(err.error.msg);
            }
        });
    }

    public download(url: string, fileName: string, cb: () => void = null) {
        var blob = this.downloadResource(url);
        blob.then(responseBlob => {
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveBlob(responseBlob, fileName);
                if (cb) {
                    cb();
                }
            }

            else {
                var url = URL.createObjectURL(responseBlob);

                var link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();

                var self = this;
                setTimeout(function() {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    if (cb) {
                        cb();
                    }
                }, 1000);
            }

        }).catch(response => {
            console.log('catch', response);
        });
    }

    public getHeadersForDocViewver(): any {
        var user = sessionStorage.getItem('user');
        if (!user) {
            //throw new Error('Kein User im Storage!');
            return;
        }
        var token = JSON.parse(user).token;
        return {
            Authorization: 'Bearer ' + token,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
        };
    }

    public getRequestHeaders(options: any = null): { headers: HttpHeaders } {
        var headers;

        var user = sessionStorage.getItem('user');
        if (user) {
            var token = JSON.parse(user).token;
            headers = new HttpHeaders(
                {
                    Authorization: 'Bearer ' + token,
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
                }
            );
        }
        else {
            headers = new HttpHeaders(
                {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
                }
            );
        }



        if (options && (options.contentType !== null && options.contentType !== undefined && options.contentType !== false)) {
            headers.append('Content-Type', options.contentType);
        }
        if (options && options.contentType === false) {
            //console.debug('FileUpload');
        }
        else {
            headers.append('Content-Type', 'application/json');
        }

        if (options && options.enctype) {
            headers.append('enctype', options.enctype);
        }

        if (options && options.responseType) {
            var result1 = {
                headers: {} as HttpHeaders,
                responseType: {}
            };

            result1.responseType = options.responseType;
            result1.headers = headers;
            return result1;
        }
        else {
            var result2 = {
                headers: {} as HttpHeaders
            };
            result2.headers = headers;
            return result2;
        }
    }

    get<T>(path: string): Observable<T> {
        let options = this.getRequestHeaders();
        return this.http.get<T>(this.baseUrl + path, options);
    }

    post<T>(path: string, data: any, options: any = null): Observable<T> {
        let _options = this.getRequestHeaders(options);
        return this.http.post<T>(this.baseUrl + path, data, _options);
    }

    private log(msg: string, paras?: any[]) {
        if (this.debug) {
            console.log(msg, paras);
        }
    }
}
