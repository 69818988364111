
export enum Roles {
    Administrator = 'Administrator',

    Traeger_Lesen = 'Traeger Lesen',
    Traeger_Schreiben = 'Traeger Schreiben',

    Traeger_Registrierung_Lesen = 'Traeger Registrierung Lesen',
    Traeger_Registrierung_Schreiben = 'Traeger Registrierung Schreiben',

    Einrichtung_Lesen = 'Einrichtung Lesen',
    Einrichtung_Schreiben = 'Einrichtung Schreiben',

    Benutzer_MBJS_Lesen = 'Benutzer MBJS Lesen',
    Benutzer_MBJS_Schreiben = 'Benutzer MBJS Schreiben',

    Benutzer_Traeger_Lesen = 'Benutzer Traeger Lesen',
    Benutzer_Traeger_Schreiben = 'Benutzer Traeger Schreiben',

    Globale_Vorlagen_Lesen = 'Globale Vorlagen Lesen',
    Globale_Vorlagen_Schreiben = 'Globale Vorlagen Schreiben',

    Vorlagen_Lesen = 'Vorlagen Lesen',
    Vorlagen_Schreiben = 'Vorlagen Schreiben',

    Kontakte_Lesen = 'Kontakte Lesen',
    Kontakte_Schreiben = 'Kontakte Schreiben',

    Dokumente_Lesen = 'Dokumente Lesen',
    Dokumente_Schreiben = 'Dokumente Schreiben',

    AntragBE_Lesen = 'AntragBE Lesen',
    AntragBE_Schreiben = 'AntragBE Schreiben',

    Katalog_Lesen = 'Katalog Lesen',
    Katalog_Schreiben = 'Katalog Schreiben',

    Zugriffsrollen_Lesen = 'Zugriffsrollen Lesen',
    Zugriffsrollen_Schreiben = 'Zugriffsrollen Schreiben',

    Meldungen_Lesen = 'Meldungen Lesen',
    Meldungen_Schreiben = 'Meldungen Schreiben',

    Meldepflichten_Lesen = 'Meldepflichten Lesen',
    Meldepflichten_Schreiben = 'Meldepflichten Schreiben',

    Ueberbelegung_Lesen = 'Überbelegung Lesen',
    Ueberbelegung_Schreiben = 'Überbelegung Schreiben',

    Anrechnung_Lesen = 'Anrechnung Lesen',
    Anrechnung_Schreiben = 'Anrechnung Schreiben'
}

//   userRoleNeeded_hidden='Traeger_Registrierung_Schreiben'

export namespace Roles {
    export function keys(): Array<any> {
        var values = Object.values(Roles) as string[];
        var results = [];
        for (var i = 0; i < values.length - 1; i++) {
            results.push({ id: i, name: values[i].replace('_', ' ') });
        }

        return results;
    }
}
