import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SpinnerService } from '@services/spinner.service';


export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

    constructor(private spinnerService: SpinnerService) {

    }

    canDeactivate(component: CanComponentDeactivate,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        var result: boolean | Observable<boolean> | Promise<boolean> = true;

        if (component && component.canDeactivate) {
            this.spinnerService.hide();
            result = component.canDeactivate();
        }

        return result;
        //return component ? component.canDeactivate ? component.canDeactivate() : true : true;
    }
}
