import { Observable } from 'rxjs';

export class ModalService {
    private modals: any[] = [];

    constructor() {
    }

    add(modal: any) {
        this.modals.push(modal);
    }

    remove(id: string) {
        //let modalToRemove = this.modals.find(x=>x.id === id);
        //let modalToRemove = find(this.modals, { id: id });
        //this.modals = without(this.modals, modalToRemove);
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, data: any) {
        let modal: any = this.modals.find(x => x.id === id);
        //let modal: any = find(this.modals, { id: id });
        if (modal) {
            modal.open(data);
        }
    }

    close(id: string) {
        let modal: any = this.modals.find(x => x.id === id);
        //let modal: any = find(this.modals, { id: id });
        modal.close();
    }

    confirm(message?: string): Observable<boolean> {
        let modal: any = this.modals.find(x => x.id === 'modal-dialogJaNein');
        //let modal: any = find(this.modals, { id: 'modal-dialogJaNein' });
        if (modal) {
            return modal.open(message);
        }

        //const confirmation = window.confirm(message || 'Sind sie sicher?');
        //return Observable.of(confirmation);
    }
}
