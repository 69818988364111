<div *ngIf="isPdfRdy && rendering && (!error || error.length === 0)" class="pdfViewerControlContainer text-center">
    <strong class="text-primary">Lade PDF...</strong>
</div>
<div *ngIf="isPdfRdy && (error && error.length > 0)" class="pdfViewerControlContainer text-center">
    <strong class="text-danger">{{error}}</strong>
</div>
<div class="pdfViewerContainer" [ngClass]="{ 'pdfViewerContainer-small': rendering }" *ngIf="isPdfRdy">
    <ngx-extended-pdf-viewer [src]="pdfSrc.url" [textLayer]="true" (pdfLoadingFailed)="onPdfError($event)"
        [authorization]="pdfSrc.withCredentials" [httpHeaders]="pdfSrc.httpHeaders" [showBookmarkButton]="false" [logLevel]="0"
        [showDownloadButton]="false" [showOpenFileButton]="false" (pageRendered)="onPdfPageRendered($event)" 
        (pdfLoaded)="onPdfRdyLoaded($event)" useBrowserLocale="true" height="100%"></ngx-extended-pdf-viewer>
</div>

<div *ngIf="isImgRdy" class="docViewerContainer">
    <dx-scroll-view #scrollView id="scrollview" [scrollByContent]="true" [scrollByThumb]="true"
        [showScrollbar]="'onHover'" reachBottomText="Updating...">
        <img style="width:100%" #imageViewver>
    </dx-scroll-view>
</div>

<div *ngIf="isEmailRdy && emailData && emailHeader" class="col-md-12 docViewerContainer">
    <dx-scroll-view #scrollView id="scrollview" [scrollByContent]="true" [scrollByThumb]="true"
        [showScrollbar]="'onHover'" reachBottomText="Updating...">
        <div class="row" style="background-color: rgba(208, 208, 208, 0.5);">
            <div class="col-md-1 text-right">
                <strong>VON:</strong>
            </div>
            <div class="col-md-8">
                <span>{{emailHeader.From}}</span>
            </div>
            <div class="col-md-1 text-right">
                <strong>DATUM:</strong>
            </div>
            <div class="col-md-2">
                <span>{{emailHeader.Date | date}}</span>
            </div>
        </div>
        <div class="row" style="background-color: rgba(208, 208, 208, 0.5);">
            <div class="col-md-1 text-right">
                <strong>AN:</strong>
            </div>
            <div class="col-md-11">
                <span>{{emailHeader.To}}</span>
            </div>
        </div>
        <div class="row" style="background-color: rgba(208, 208, 208, 0.5);">
            <div class="col-md-1 text-right">
                <strong>CC:</strong>
            </div>
            <div class="col-md-11">
                <span>{{emailHeader.Cc}}</span>
            </div>
        </div>

        <div class="row" style="background-color: rgba(208, 208, 208, 0.5);">
            <div class="col-md-12" style="border-bottom: 1px solid black;">
                <strong>Betreff: {{emailData.subject}}</strong>
            </div>
        </div>
        <div class="row" style="height:89%; overflow:auto;">
            <div class="col-md-12" [innerHTML]="emailData.body"></div>
        </div>
    </dx-scroll-view>
</div>


<div *ngIf="!isPdfRdy && !isEmailRdy && !isImgRdy" style="height: 700px; border: 1px solid black">
    <div class="col-centered col-md-6 text-center">
        <h1>Lade...</h1>
    </div>
</div>