
<div class="{{class}}">
    <label class="control-label w100" for="{{id}}">
        {{label}}<i *ngIf="showAsterik" class="glyphicon glyphicon-asterisk asterik-label"></i>
        <input [(ngModel)]="value"
               [disabled]="disabled"
               type="{{type}}"
               id="{{id}}"
               #thisInput
               [class.form-control-sm]="sm"
               [class.form-control]="!sm"
               class="form-control col-md-12"
               [attr.autocomplete]="autocomplete"
               (blur)="onBlur()">
    </label>
    <div class="row">
        <show-errors [class]="'col-md-12'" [errFontSize]="errFontSize" [control]="control" [showOnStartrError]="showOnStartrError" [fieldName]="label"></show-errors>
    </div>
</div>

<!--autocomplete='off'-->
