<nav class="navbar navbar-inverse navbar-fixed-top">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li>
          <a [routerLinkActive]="['active']" [routerLink]="['/extern/home']">
            <i class="fa fa-home fa-fw"></i>Dabea
          </a>
        </li>
        <li class="dropdown">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            &nbsp;Hilfe<span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="[]" (click)="onClickDatenschutzerklaerung($event)">
                <i class="fa fa-clone fa-fw"></i>&nbsp;Datenschutzerklärung
              </a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="[]" (click)="onClickHelp($event)">
                <i class="fa fa-question-circle-o fa-fw"></i>&nbsp;Handbuch
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="!authService.isSub()">
          <a [routerLinkActive]="['active']" [routerLink]="['/extern/selbstauskunft']">
            <i class="fa fa-id-card fa-fw"></i>Selbstauskunft
          </a>
        </li>
        <li *ngIf="!authService.isSub()">
          <a [routerLinkActive]="['active']" [routerLink]="['/extern/benutzerverwaltung']">
            <i class="fa fa-users fa-fw"></i>&nbsp;Benutzerverwaltung
          </a>
        </li>
        <li *ngIf="!authService.isSub()">
          <a [routerLinkActive]="['active']" [routerLink]="['/extern/benutzeraktivitaeten']">
            <i class="fa fa-id-card-o fa-fw"></i>&nbsp;Benutzeraktivitäten
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown">
          <a [routerLinkActive]="['active']" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            {{userName}}  <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['/extern/profil']">
                <i class="fa fa-user fa-fw"></i>Profil
              </a>
            </li>
            <li>
              <a (click)="onClickLogout()" style="cursor:pointer;">
                <span class='glyphicon glyphicon-log-out'></span> Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<dx-popup class="popup"
          *ngIf="true"
          [showTitle]="true"
          [title]="popupName"
          [height]="'90%'"
          [width]="'80%'"
          [dragEnabled]="false"
          [closeOnOutsideClick]="false"
          (onHidden)="onHiddenPopup($event)"
          [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <cz-document-viewer *ngIf="popupVisible" [pdfUrl]="pdfSrc"></cz-document-viewer>
  </div>

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{
            text: 'Download',
            onClick: download
        }">
  </dxi-toolbar-item>

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{
            text: 'Schließen',
            onClick: hidePopup
        }">
  </dxi-toolbar-item>

</dx-popup>
