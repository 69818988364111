
//  common
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { DndModule } from '@beyerleinf/ngx-dnd';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaComponent
} from 'ng-recaptcha';

// services
import { RoutingState } from 'RoutingState';
import { AjaxService } from 'AjaxService';
import { AuthService } from 'AuthService';
import { ModalService } from 'ModalService';
import { AuthGuard } from 'AuthGuard';
import { CanDeactivateGuard } from 'CanDeactivateGuard';
import { ExternGuard } from 'src/app/guards/extern.guard';
import { RememberService } from 'RememberService';
import { ServerPollService } from 'ServerPollService';
// import { TableSearchService } from 'TableSearchService';
import { SpinnerService } from '@services/spinner.service';

//  directives
import { UserRoleNeededHiddenDirective } from '../directives/user-role-needed/userRoleNeeded-hidden.directive';
import { UserRoleNeededDisabledDirective } from '../directives/user-role-needed/userRoleNeeded-disabled.directive';
import { NotForExternDirective } from '../directives/user-role-needed/notForExtern.directive';
import { OpacitydDirective } from '../directives/opacity/opacity.directive';
import { MouseWheelDirective } from '../directives/mouse/mousewheel.directive';
import { NotForSubDirective } from '@shared/directives/user-role-needed/notForSub.directive';

// pipes
import { TraegerRegEntscheidungPipe } from '../pipes/traegerRegEntscheidung.pipe';
import { TraegerRegBearbeitungsStatusPipe } from '../pipes/traegerRegBearbeitungsStatus.pipe';

import { TraegerAntragBeStatusPipe } from '../pipes/traegerAntragBeStatus.pipe';

// components
import { WiedervorlagenComponent } from '@shared/wiedervorlagen/wiedervorlagen.component';
import { BemerkungenComponent } from '@shared/bemerkungen/bemerkungen.component';
import { BemerkungenPopupComponent } from '@shared/bemerkungen-popup/bemerkungen-popup.component';
import { WiedervorlagenPopupComponent } from '@shared/wiedervorlagen-popup/wiedervorlagen-popup.component';
import { DokumentPopupComponent } from '@shared/dokumente-popup/dokumente-popup.component';
import { TooltipsComponent } from '@shared/tooltips/tooltips.component';
import { EmailButtonComponent } from '@shared/emailButton/email-button.component';

import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

import { FileUploaderButtonComponent } from '@shared/fileUploaderButton/fileUploaderButton.component';

import { ModalErrorComponent } from '../modal/error/modal-error.component';
import { ModalFeedbackComponent } from '../modal/feedback/modal-feedback.component';
import { ModalDialogJaNeinComponent } from '../modal/DialogJaNein/modal-dialogJaNein.component';

import { LabeledInputComponent } from '../labeled-input/labeled-input.component';
import { ShowErrorsComponent } from '../show-errors/show-errors.component';
import { KontaktDetailComponent, KontaktComponent, KontaktModalComponent } from '../kontakt/';
import { AenderungenComponent } from '../aenderungen/aenderungen.component';
import { DokumenteComponent } from '../dokumente/dokumente.component';
import { DokumenteArchiveComponent } from '../dokumente-archive/dokumente-archive.component';
import { DocumentViewerPopupComponent } from '@shared/document-viewer-popup/document-viewer-popup.component';
import { DocumentViewerComponent } from '@shared/document-viewer/document-viewer.component';
import { DocumentArchiveViewerComponent } from '@shared/document-viewer-archive/document-viewer-archive.component';
import { DocUploadPopupComponent } from '@shared/dokumente/uploadPopup/uploadPopup.component';
import { DocBezeichnungPopupComponent } from '@shared/dokumente/file-bezPopup/file-bezPopup.component';
import { MappeBezeichnungPopupComponent } from '@shared/dokumente/mappe-bezPopup/mappe-bezPopup.component';
import { UploadWaitComponent } from '@shared/dokumente/upload-wait/upload-wait.component';
import { DownloadButtonComponent } from '@shared/download-button/download-button.component';
import { LetzteAenderungComponent } from '../../letzteAenderung/letzte-aenderung.component';
import { EmailKontaktButtonComponent } from '../kontakt/emailButton/email-kontakt-button.component';
import { VorlagenSelectComponent } from '../vorlagen-select/vorlagen-select.component';
import { StammblattComponent } from '../stammblatt/stammblatt.component';
import { MeldungEntityEoEComponent } from '@shared/mEoE/mEoE.component';
import { OertlichePruefungPopupInternComponent } from '@shared/oertliche-pruefung-popup/oertliche-pruefung-popup.component';
import { ErlaubnisComponent } from '@shared/erlaubnis-popup/erlaubnis/cz-erl.component';
import { ErlaubnisPopupComponent } from '@shared/erlaubnis-popup/cz-erl-popup.component';
import { AntragBeExternMitarbeiterPopupComponent } from '@components/home-extern/antraege-be/aBe-e-mitA-popup/aBe-e-mitA-popup.component';
import { AntragBeExternUebersichtComponent } from '@components/home-extern/antraege-be/aBe-e-uebersicht/aBe-e-uebersicht.component';
import { AntragBeInternUebersichtComponent } from '@components/antragBE-intern/aBe-i-uebersicht/aBe-i-uebersicht.component';
import { AusnahmeComponent } from '../ausnahme-popup/ausnahme/ausnahme.component';
import { AusnahmePopupComponent } from '../ausnahme-popup/ausnahme-popup.component';


import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxFormModule } from 'devextreme-angular/ui/form';
// import { DxTemplateModule } from 'devextreme-angular/core/template';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import { DxSortableModule } from 'devextreme-angular/ui/sortable';

import { locale } from 'devextreme/localization';
import { TraegerZugangsatenPopupComponent } from '../traeger-zugangsdaten-popup/traeger-zugangsdaten.component';
import { PasswortrichtlinienService } from '@services/passwortrichtlinien.service';
import { GoTraegerComponent } from '@shared/go-traeger/go-traeger.component';
//import 'devextreme-intl';



// let messagesDe = require('devextreme/localization/messages/de.json');
locale(navigator.language);


const globalSettings: RecaptchaSettings = { siteKey: '6Lf7aJIUAAAAAMlsNcnRvf9X2bETHvRShmuD8kpz' };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    HttpClientModule,
    // PdfViewerModule,
    NgxExtendedPdfViewerModule,
    // NgxExtendedPdfViewerModule,
    // DndModule,
    DxSortableModule,
    // DragDropModule,
    DxDataGridModule,
    // DxTemplateModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxValidatorModule,
    DxFormModule,
    DxValidationGroupModule,
    DxTextBoxModule,
    DxTabsModule,
    DxSwitchModule,
    DxDropDownBoxModule,
    DxListModule,
    DxTextAreaModule,
    DxLookupModule,
    DxDateBoxModule,
    DxRadioGroupModule,
    DxTooltipModule,
    DxToolbarModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxAccordionModule,
    DxPopupModule,
    DxScrollViewModule,
    DxPopoverModule,
    DxPivotGridModule,
    DxTreeViewModule
  ],
  declarations: [
    TooltipsComponent,
    DocumentViewerPopupComponent,

    UserRoleNeededHiddenDirective,
    UserRoleNeededDisabledDirective,
    NotForExternDirective,
    NotForSubDirective,
    OpacitydDirective,
    MouseWheelDirective,

    FileUploaderButtonComponent,
    ModalErrorComponent,
    ModalFeedbackComponent,
    ModalDialogJaNeinComponent,
    LabeledInputComponent,
    ShowErrorsComponent,
    KontaktDetailComponent,
    KontaktComponent,
    KontaktModalComponent,
    AenderungenComponent,
    EmailKontaktButtonComponent,
    VorlagenSelectComponent,
    StammblattComponent,
    DokumenteComponent,
    DokumenteArchiveComponent,
    DocumentViewerComponent,
    DocumentArchiveViewerComponent,
    DocUploadPopupComponent,
    DownloadButtonComponent,
    DocBezeichnungPopupComponent,
    DokumentPopupComponent,
    MappeBezeichnungPopupComponent,
    UploadWaitComponent,
    LetzteAenderungComponent,
    TraegerRegEntscheidungPipe,
    TraegerRegBearbeitungsStatusPipe,
    TraegerAntragBeStatusPipe,
    EmailButtonComponent,
    WiedervorlagenComponent,
    WiedervorlagenPopupComponent,
    BemerkungenComponent,
    BemerkungenPopupComponent,
    MeldungEntityEoEComponent,
    OertlichePruefungPopupInternComponent,
    ErlaubnisPopupComponent,
    ErlaubnisComponent,

    AusnahmePopupComponent,
    AusnahmeComponent,

    AntragBeExternMitarbeiterPopupComponent,
    AntragBeExternUebersichtComponent,
    AntragBeInternUebersichtComponent,

    TraegerZugangsatenPopupComponent,

    GoTraegerComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // PdfViewerModule,
    NgxExtendedPdfViewerModule,
    // DndModule,
    // DragDropModule,
    DxSortableModule,
    DxDataGridModule,
    // DxTemplateModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxValidatorModule,
    DxFormModule,
    DxValidationGroupModule,
    DxTextBoxModule,
    DxTabsModule,
    DxSwitchModule,
    DxDropDownBoxModule,
    DxListModule,
    DxTextAreaModule,
    DxLookupModule,
    DxDateBoxModule,
    DxRadioGroupModule,
    DxTooltipModule,
    DxToolbarModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxAccordionModule,
    DxPopupModule,
    DxScrollViewModule,
    DxPopoverModule,
    DxPivotGridModule,
    DxTreeViewModule,

    TooltipsComponent,
    UserRoleNeededHiddenDirective,
    UserRoleNeededDisabledDirective,
    NotForExternDirective,
    NotForSubDirective,
    OpacitydDirective,
    MouseWheelDirective,
    DocumentViewerPopupComponent,
    DocumentViewerComponent,
    DocumentArchiveViewerComponent,

    FileUploaderButtonComponent,
    RecaptchaComponent,
    ModalErrorComponent,
    ModalFeedbackComponent,
    ModalDialogJaNeinComponent,
    LabeledInputComponent,
    ShowErrorsComponent,
    KontaktDetailComponent,
    KontaktComponent,
    KontaktModalComponent,
    AenderungenComponent,
    EmailKontaktButtonComponent,
    VorlagenSelectComponent,
    StammblattComponent,
    DokumenteComponent,
    DokumenteArchiveComponent,
    DocUploadPopupComponent,
    DownloadButtonComponent,
    DocBezeichnungPopupComponent,
    DokumentPopupComponent,
    MappeBezeichnungPopupComponent,
    UploadWaitComponent,
    LetzteAenderungComponent,
    //ReadableTraegerNummerPipe,
    TraegerRegEntscheidungPipe,
    TraegerRegBearbeitungsStatusPipe,
    TraegerAntragBeStatusPipe,
    EmailButtonComponent,
    WiedervorlagenComponent,
    WiedervorlagenPopupComponent,
    BemerkungenComponent,
    BemerkungenPopupComponent,
    MeldungEntityEoEComponent,
    OertlichePruefungPopupInternComponent,
    ErlaubnisPopupComponent,
    ErlaubnisComponent,

    AusnahmePopupComponent,
    AusnahmeComponent,

    AntragBeExternMitarbeiterPopupComponent,
    AntragBeExternUebersichtComponent,
    AntragBeInternUebersichtComponent,

    TraegerZugangsatenPopupComponent,
    GoTraegerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: globalSettings,
        },
        SpinnerService,
        RoutingState,
        AjaxService,
        AuthService,
        ModalService,
        AuthGuard,
        CanDeactivateGuard,
        ExternGuard,
        RememberService,
        ServerPollService,
        // TableSearchService,
        PasswortrichtlinienService
      ]
    };
  }
}
