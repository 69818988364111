import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { AuthService } from 'AuthService';
import { Roles } from 'Roles';

@Directive({ selector: '[userRoleNeeded_hidden]' })
export class UserRoleNeededHiddenDirective implements OnInit {

  constructor(public el: ElementRef, public renderer: Renderer2, private authService: AuthService) { }

  @Input() userRoleNeeded_hidden: string;

  ngOnInit() {
    if (!this.userRoleNeeded_hidden) {
      return;
    }

    var _neededRoles: Roles[] = [];


    var rn = this.userRoleNeeded_hidden.split(',').map(x => x.trim());



    for (var i = 0; i < rn.length; i++) {
      var role = Roles[rn[i]] as Roles;

      if (role) {
        _neededRoles.push(role);
      }
      else {
        throw new Error('Konnte \'' + rn[i] + '\' keiner vorhandenen Zugriffsbeschränkung zuordnen!');
      }
    }


    if (_neededRoles && _neededRoles.length > 0) {

      if (!this.authService.hasOneRole(_neededRoles)) {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      }
    }
  }
}
