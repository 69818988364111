import { Injectable } from '@angular/core';
import { AjaxService } from 'AjaxService';
import { IPasswortrichtlinieViewModel } from '@shared/models/passwortrichtlinie.model';

@Injectable()
export class PasswortrichtlinienService {

  pattern_sonderzeichen = /[\W_]/g;
  pattern_zahlen = /[\d]/g;
  pattern_grossbuchstaben = /[A-Z]/g;

  passwortrichtlinie: IPasswortrichtlinieViewModel;

  constructor(private ajaxService: AjaxService) {
    this.check = this.check.bind(this);
    this.load();
  }

  load() {
    this.ajaxService.post<IPasswortrichtlinieViewModel>('api/Options/GetPasswortrichtline', null).toPromise().then(response => {
      this.passwortrichtlinie = response;
    });
  }

  check(e) {
    if (!e.value) {
      return true;
    }

    let msg = '';

    if (e.value.length < this.passwortrichtlinie.passwortMinCount) {
      msg = this.addToMsg(msg, this.passwortrichtlinie.passwortMinCount + ' Zeichen lang sein');
    }

    if (this.count(e.value, this.pattern_sonderzeichen) < this.passwortrichtlinie.passwortSonderzeichenMinCount) {
      msg = this.addToMsg(msg, this.passwortrichtlinie.passwortSonderzeichenMinCount + ' Sonderzeichen beinhalten');
    }

    if (this.count(e.value, this.pattern_zahlen) < this.passwortrichtlinie.passwortZahlenMinCount) {
      msg = this.addToMsg(msg, this.passwortrichtlinie.passwortZahlenMinCount + ' Zahlen beinhalten');
    }

    if (this.count(e.value, this.pattern_grossbuchstaben) < this.passwortrichtlinie.passwortGrossbuchstabenMinCount) {
      msg = this.addToMsg(msg, this.passwortrichtlinie.passwortGrossbuchstabenMinCount + ' Großbuchstaben beinhalten');
    }

    if (msg.length > 0) {
      e.rule.message = msg + '!';
      return false;
    }
    else {
      return true;
    }
  }

  private addToMsg(msg: string, addMsg: string) {
    if (msg.length > 0) {
      msg += ', ';
    }
    else{
      msg += 'Passwort muß ';
    }
    msg += addMsg;
    return msg;
  }

  private count(pw: string, req: RegExp) {
    const match = pw.match(req);
    return match ? match.length : 0;
  }
}
