import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Event as RouterEvent, NavigationStart } from '@angular/router';


@Injectable()
export class RoutingState {
    private history = new Map<string, any>();

    constructor(
        private router: Router
    ) { }

    public loadRouting(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
    }

    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationEnd) {
            var urlTree = this.router.parseUrl(event.urlAfterRedirects);
            var urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');

            if (this.history[this.history.size - 1] !== urlWithoutParams) {
                this.history.set(urlWithoutParams, {} );
            }
        }
    }

    public getHistory(): string[] {
        return Array.from(this.history.keys());
    }

    public getPreviousUrl(): string {
        var hisArray = this.getHistory();

        var url = hisArray[hisArray.length - 2];
        var url_todelete = hisArray[hisArray.length - 1];

        if (url_todelete) {
            this.history.delete(url_todelete);
        }

        if (!url) {
            url = '/home';
        }

        return url;
    }

    public goBack(data: any = null) {
        this.router.navigate([this.getPreviousUrl()], { queryParams: { back: true } });
    }
}
