import { Component, ElementRef, Input, OnInit, OnDestroy, ApplicationRef, ChangeDetectorRef  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AjaxService } from '../../../../services/ajax.service';

import { ModalService } from 'ModalService';
import * as $ from 'jquery';


@Component({
    moduleId: module.id.toString(),
    selector: 'app-error-modal',
    //template: '<ng-content></ng-content>'
    templateUrl: 'modal-error.component.html',
    styleUrls: ['modal-error.component.css']
})

export class ModalErrorComponent implements OnInit, OnDestroy {
    @Input() id: string;
    //error: Error;
    error: any;
    errorJsonString: string;
    name: string;
    message: SafeHtml;
    stack: SafeHtml;
    exceptionDetails: boolean;

    private element: JQuery<HTMLElement>;

    constructor(private ajaxService: AjaxService, private modalService: ModalService, el: ElementRef, private _sanitizer: DomSanitizer, private applicationRef: ApplicationRef, private cdr: ChangeDetectorRef ) {
        this.element = $(el.nativeElement);
        this.ajaxService.config.subscribe(c => {
            this.exceptionDetails = c.exceptionDetails;
        });
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        this.element.appendTo('body');

        // close modal on background click
        //this.element.on('click', function(e: any) {
        //    var target = $(e.target);
        //    if (!target.closest('.app-modal-body').length) {
        //        modal.close();
        //    }
        //});

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(error: any): void {

        this.error = error;
        this.name = error.name;
        //this.message = error.message;
        this.message = this.sanitizeHtml(error.message);
        if (error.stack) {
            //this.stack = this.sanitizeHtml(error.stack.replace(new RegExp('\n', 'g'), '<br />'));
            this.stack = this.sanitizeHtml(error.stack);
        }

        if (error && error.error && typeof error.error === 'object') {
            this.errorJsonString = JSON.stringify(error.error, this.getCircularReplacer(), 2);
        }

        this.element.show();

        this.cdr.detectChanges();
        //this.applicationRef.tick();

        $('body').addClass('modal-open');
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

    refresh() {
        this.applicationRef.tick();
    }

    OnSendFeedback() {
        this.modalService.close('modal-error');
        this.modalService.open('modal-feedback', this.error);
    }

    // close modal
    close(): void {
        this.element.hide();
        $('body').removeClass('modal-open');
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    sanitizeHtml(html): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }
}
