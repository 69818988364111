import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles } from 'Roles';
import { JwtHelper } from '../helper/jwt-helper';
import { ServerPollService } from './server-poll.service';

interface ITokenContainer {
  token: string;
}

interface IUserInfoResultViewModel {
  version: string;
  isNew: boolean;
  isFreigegeben?: boolean;
  isExtern: boolean;
  isSub: boolean;
  userId: number;
  name: string;
  vorname: string;
  email: string;
  sub: string;
  role: any[];
}

@Injectable()
export class AuthService {

  private isLoggedIn = new Subject<boolean>();
  jwtHelper = new JwtHelper();

  constructor(private router: Router, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private serverPollService: ServerPollService) {

  }

  login(userRequest: any, cbOk: () => void = null, cbEr: (err: any) => void = null) {
    const toSend = { userName: userRequest.username ? btoa(unescape(encodeURIComponent(userRequest.username))) : '', password: userRequest.password ?  btoa(unescape(encodeURIComponent(userRequest.password))) : '' };
    this.http.post(this.baseUrl + 'api/Account/Token', toSend)
      .pipe(
        map((response) => {

          let token = (response as ITokenContainer).token;

          if (token) {
            this.userInfos(token).subscribe(userInfoToken => {
              sessionStorage.setItem('user', JSON.stringify({ token: token, info: userInfoToken }));
              this.isLoggedIn.next(true);
              cbOk();
            });
          } else {
            this.isLoggedIn.next(false);
            cbEr(response);

          }
        })
      ).subscribe({
        next: null, error: err => {
          this.isLoggedIn.next(false);
          cbEr(err);
        }
      });
  }

  private userInfos(token: string): Observable<string> {

    return this.http.post(this.baseUrl + 'api/Account/UserInfo', null, {
      headers: new HttpHeaders(
        {
          Authorization: 'Bearer ' + token,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          'Content-Type': 'application/json'
        })
    }).pipe(
      map((response) => {
        let token = (response as ITokenContainer).token;
        if (token) {
          return token;
        } else {
          return null;
        }
      })
    );
  }

  logout(): void {
    this.serverPollService.Stop();
    this.isLoggedIn.next(false);

    sessionStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  logoutWithError(error): void {
    this.isLoggedIn.next(false);

    sessionStorage.removeItem('user');
    this.router.navigate(['/login']);
    setTimeout(() => {
      throw error;
    }, 200);
  }

  public isAuth(): boolean {
    if (sessionStorage.getItem('user')) {
      this.isLoggedIn.next(true);
      return true;
    }

    this.isLoggedIn.next(false);
    return false;
  }


  getLoginState(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  isSelf(id: number): boolean {
    var user = sessionStorage.getItem('user');
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    if (+decodedIdToken.UserId === id) {
      return true;
    }
    else {
      return false;
    }
  }

  isTest(): boolean {
    var user = sessionStorage.getItem('user');
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    return decodedIdToken.IsTest === 'True';
  }

  isNew(): boolean {
    var user = sessionStorage.getItem('user');
    var token = JSON.parse(user) ? JSON.parse(user).info : null;
    let decodedIdToken = token ? this.jwtHelper.decodeToken(token) : null;
    return decodedIdToken ? decodedIdToken.IsNew === 'True' : false;
  }

  isFreigegeben(): boolean {
    var user = sessionStorage.getItem('user');
    var token = JSON.parse(user) ? JSON.parse(user).info : null;
    let decodedIdToken = token ? this.jwtHelper.decodeToken(token) : null;
    return decodedIdToken ? decodedIdToken.isFreigegeben === 'True' : false;
  }

  isExtern(): boolean {
    var user = sessionStorage.getItem('user');
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    return decodedIdToken.IsExtern === 'True';
  }

  isSub(): boolean {
    var user = sessionStorage.getItem('user');
    if(user){
      var token = JSON.parse(user).info;
      let decodedIdToken = this.jwtHelper.decodeToken(token);
      return decodedIdToken.IsSub === 'True';
    }
    return false;
  }

  getUserName(): string {
    var user = sessionStorage.getItem('user');
    if (!user) {
      return '';
    }
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);

    return decodedIdToken.sub;
  }

  getVersion(): string {
    var user = sessionStorage.getItem('user');
    if (!user) {
      return '';
    }
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);

    return decodedIdToken.Version;
  }

  getUserEMail(): string {
    var user = sessionStorage.getItem('user');
    if (!user) {
      return '';
    }
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);

    return decodedIdToken.Email;
  }

  getUserId(): number {
    var user = sessionStorage.getItem('user');
    if (!user) {
      return -1;
    }
    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    return decodedIdToken.UserId;
  }

  getNames(): any {
    var user = sessionStorage.getItem('user');

    if (!user) {
      return '';
    }

    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    return { name: decodedIdToken.Name, vorname: decodedIdToken.Vorname };
  }

  public hasOneRole(rolesNeed: Roles[] | string[]): boolean {


    if (rolesNeed.length === 0) {
      return true;
    }

    var __rn: Roles[] = [];

    __rn = rolesNeed as Roles[];


    var user = sessionStorage.getItem('user');

    if (!user) {
      return false;
    }

    var token = JSON.parse(user).info;
    let decodedIdToken = this.jwtHelper.decodeToken(token);
    var _rolesHave = decodedIdToken.role as string[];

    if (!_rolesHave) {
      return false;
    }

    if (_rolesHave.indexOf(Roles.Administrator) !== -1) {
      return true;
    }

    var _rolesNeeded = __rn.map(x => {
      return x.replace(/\s/g, '_').replace(/Ü/g, 'Ue');
    });


    if (!(_rolesHave instanceof Array)) {
      _rolesHave = [_rolesHave];
    }

    var self = this;

    var _rn_tmp = _rolesNeeded.map(x => this.splitIt(x));
    var _rh_tmp = _rolesHave.map(x => this.splitIt(x));

    var result;


    for (var i = 0; i < _rn_tmp.length; i++) {
      var _tmp = _rh_tmp.filter(x => x[0] === _rn_tmp[i][0]);

      var yes = 0;

      for (var u = 0; u < _tmp.length; u++) {
        var tmp = _tmp[u];
        if (tmp) {
          result = tmp;
          if (tmp[1] === 'Schreiben') {

            yes++;
          }
          else if (_rn_tmp[i][1] === 'Lesen' && tmp[1] === 'Lesen') {
            yes++;
          }
        }
      }

      if (yes > 0) {
        return true;
      }
    }


    return false;

  }

  private splitIt(str: string): string[] {
    var tmp = str.split('_');
    var last = tmp.pop();

    if (tmp.length) {
      var result = [tmp.join('_'), last];
    }
    else {
      var result = [last];
    }

    return result;
  }
}
