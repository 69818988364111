import { Component, ElementRef, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalService } from 'ModalService';
import * as $ from 'jquery';
import { AjaxService } from 'AjaxService';
import { AuthService } from 'AuthService';
import { Router } from '@angular/router';
import { SpinnerService } from '@services/spinner.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';

interface IFeedBackViewModel {
  userName: string;
  email: string;
  betreff: string;
  beschreibung: string;
  route: string;
  error: string;
  browserName: string;
  browserVersion: string;
}

@Component({
  moduleId: module.id.toString(),
  selector: 'app-feedback-modal',
  templateUrl: 'modal-feedback.component.html',
  styleUrls: ['modal-feedback.component.css']
})

export class ModalFeedbackComponent implements OnInit, OnDestroy {
  @Input() id: string;
  isErrorFeedback: boolean = false;
  fb: IFeedBackViewModel = {} as IFeedBackViewModel;
  errMsg: string;
  loading = false;
  @ViewChild('feedBForm', { static: false }) feedBForm: DxFormComponent;
  private element: JQuery<HTMLElement>;

  constructor(private authService: AuthService, private router: Router, private ajaxService: AjaxService, private spinnerService: SpinnerService, private modalService: ModalService, el: ElementRef, private _sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    this.element = $(el.nativeElement);
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    this.element.appendTo('body');

    // close modal on background click
    this.element.on('click', (e: any) => {
      const target = $(e.target);
      if (!target.closest('.app-modal-body').length) {
        modal.close();
      }
    });



    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(error: any): void {
    this.fb.email = this.authService.getUserEMail();
    this.fb.betreff = '';
    this.fb.beschreibung = '';

    const er = (error as Error);

    this.fb.error = JSON.stringify(er, null, '\t');
    if (error) {
      this.isErrorFeedback = true;
      this.fb.betreff = er.name + ': ' + er.message;
    }

    this.element.show();

    this.cdr.detectChanges();

    $('body').addClass('modal-open');
  }

  // close modal
  close(): void {
    this.element.hide();
    $('body').removeClass('modal-open');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  sanitizeHtml(html): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }


  onClickSend() {
    const validationResult = this.feedBForm.instance.validate();
    if (!validationResult.isValid) {
      this.errMsg = '';
      for (let err of validationResult.brokenRules) {
        this.errMsg += err.message + '<br/>';
      }
    }
    else {
      this.startLoading();
      this.fb.browserName = this.detectBrowserName();
      this.fb.browserVersion = this.detectBrowserVersion();
      this.fb.route = this.router.url;
      this.fb.userName = this.authService.getUserName();
      this.ajaxService.post('api/Email/SendFeedbackEmail', this.fb).subscribe(() => {
        this.stopLoading();
        this.closeModal('modal-feedback');
      });
    }
  }

  private startLoading() {
    this.spinnerService.show();
    this.loading = true;
  }

  private stopLoading() {
    this.spinnerService.hide();
    this.loading = false;
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    const userAgent = navigator.userAgent;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
  }
}
