<div class="container-fluid">
    <div class="row">
        <div class="col-md-8 text-center">
            <h1>Willkommen {{userName}}</h1>
        </div>
        <div class="col-md-4" *ngIf="userId > 0 && hasCounts()">
            <div class="panel panel-default">
                <div class="panel-heading">neu Einreichungen</div>
                <div class="panel-body">
                    <div class="col-md-12">
                        <p *ngIf="counts.traegerRegCount>0">
                            neue Träger-Registrierungen: <strong class="text-danger">{{counts.traegerRegCount}}</strong>
                        </p>
                        <p *ngIf="counts.antragBeCount>0">
                            neue Anträge: Betriebserlaubnis: <strong class="text-danger">{{counts.antragBeCount}}</strong>
                        </p>
                        <p *ngIf="counts.antragUeberbelegungCount>0">
                            neue Anträge: Überbelegung: <strong class="text-danger">{{counts.antragUeberbelegungCount}}</strong>
                        </p>
                        <p *ngIf="counts.antragAnrechnungCount>0">
                            neue Anträge: Anrechnung: <strong class="text-danger">{{counts.antragAnrechnungCount}}</strong>
                        </p>
                        <p *ngIf="counts.meldungenEoECount>0">
                            neue Meldungen-EoE: <strong class="text-danger">{{counts.meldungenEoECount}}</strong>
                        </p>
                        <p *ngIf="counts.meldepflichtenCount>0">
                            neue Meldepflichten: <strong class="text-danger">{{counts.meldepflichtenCount}}</strong>
                        </p>
                        <p *ngIf="counts.meldungenJaehrlichCount>0">
                            neue jährliche Meldungen: <strong class="text-danger">{{counts.meldungenJaehrlichCount}}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8" *ngIf="userId > 0">
            <cz-home-wiedervorlage [userId]="userId"></cz-home-wiedervorlage>
        </div>
        <div class="col-md-4" *ngIf="userId > 0">
            <cz-home-info></cz-home-info>
        </div>
    </div>
</div>
