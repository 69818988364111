

export enum EntityArt {
    Unbekannt,

    Traeger,
    TraegerGruppe,
    TraegerRegister,

    Kontakt,
    KontaktType,

    Verband,
    Landkreis,
    Jugendamt,
    Rechtsform,
    Angebotsgruppe,
    Angebotsform,
    Benutzer,
    Ausbildung,
    Taetigkeit,
    Grund,
    Verwaltungsgericht,
    EoEKatalog,
    Dokument,
    RechtsgrundlageUnterbringung,

    Einrichtung,
    HauptEinrichtung,
    UnterEinrichtung,
    Einrichtungspersonal,

    StaticVorlagen,
    DynamicVorlagen,

    Meldepflichten,
    MeldepflichtenTraegerAnschrift,
    MeldepflichtenEinrichtungSchliessung,
    MeldepflichtenKontakte,
    MeldepflichtenKonzeption,
    MeldepflichtenLeitung,
    MeldepflichtenBetreuung,


    MeldungenJaehrlich,
    MeldungenJaehrlichUnterEinrichtungen,
    MeldungenJaehrlichUnterEinrichtungenStichtagPerson,
    MeldungenJaehrlichUnterEinrichtungenEntlassungenPerson,
    MeldungenJaehrlichUnterEinrichtungenAufnahmePerson,
    MeldungenJaehrlichUnterEinrichtungenBetreuungPerson,

    MeldungenEoE,

    AntragBe,
    AntragBeEinrichtungsteile,
    AntragBePersonal,
    AntragBeMitarbeiter,
    AntragBeMitarbeiterLebenslauf,

    Erlaubnis,
    Mitarbeiter,

    Antrag_Anerkennung,
    Antrag_Ueberbelegung,
    Antrag_Ueberbelegung_Personen,

    Ausnahme
}


export namespace EntityArt {
    export function keys(): Array<any> {
        var values = Object.values(EntityArt) as string[];
        var results = [];
        for (var i = 0; i < values.length - 1; i++) {
            results.push({ id: i, name: values[i] });
        }

        return results;
    }

    export function getValues(): Array<EntityArt> {
        var values = Object.values(EntityArt) as string[];
        var results = [];
        for (var i = 0; i < values.length - 1; i++) {
            results.push(values[i]);
        }

        return results;
    }

    export function shortKey(eArt: EntityArt): string {
        var result = '';
        var _eArt = EntityArt[eArt];
        switch (_eArt) {
            case 'Traeger':
                result = 'Träger';
                break;
            case 'Einrichtung':
                result = 'Einrichtung';
                break;
            case 'HauptEinrichtung':
                result = 'Haupteinrichtung';
                break;
            case 'UnterEinrichtung':
                result = 'Untereinrichtung';
                break;
            case 'TraegerRegister':
                result = 'Träger-Registrierung';
                break;
            case 'AntragBe':
                result = 'Antrag-BE';
                break;
            case 'Grund':
                result = 'Grund';
                break;
            case 'MeldepflichtenTraegerAnschrift':
                result = 'Mpf. Anschrift';
                break;
            case 'MeldepflichtenEinrichtungSchliessung':
                result = 'Mpf. Schliessung';
                break;
            case 'MeldepflichtenKontakte':
                result = 'Mpf. Kontakte';
                break;
            case 'MeldepflichtenKonzeption':
                result = 'Mpf. Konzeption';
                break;
            case 'MeldepflichtenLeitung':
                result = 'Mpf. Leitung';
                break;
            case 'MeldepflichtenBetreuung':
                result = 'Mpf. Betreuung';
                break;
            case 'Einrichtungspersonal':
                result = 'Personal';
                break;
            case 'MeldungenJaehrlich':
                result = 'Jährliche Meldung';
                break;
            case 'MeldungenEoE':
                result = 'Meldung-EoE';
                break;
            case 'Antrag_Ueberbelegung':
                result = 'Antrag-Überbelegung';
                break;
            case 'Antrag_Anerkennung':
                result = 'Antrag-Anrechnung Personen';
                break;
            case 'Einrichtung':
                result = 'Einrichtung';
                break;
            default:
                result = eArt.toString();
                break;
        }
        return result;
    }
}

//export namespace EntityArt {
//    export function shortKeys(): Array<any> {
//        var values = Object.values(EntityArt);
//        var results = [];
//        for (var i = 0; i < values.length - 1; i++) {
//            results.push({ id: i, name: values[i].replace('_', ' ') })
//        }

//        return results;
//    }
//}
