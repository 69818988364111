import { Injectable } from '@angular/core';


@Injectable()
export class RememberService {
   
    constructor() {
    }

    LoadFromSession(key: string): any {
        var data = sessionStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }

    SaveFromSession(key: string, data: any) {
        setTimeout(() => {
            sessionStorage.setItem(key, JSON.stringify(data));
        }, 0);
    }

    RemoveFromSession(key: string) {
        sessionStorage.removeItem(key);
    }

    LoadFromPersist(key: string): any {
        var data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }

    SaveFromPersist(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    RemoveFromPersist(key: string) {
        localStorage.removeItem(key);
    }
}
