

<div *ngIf="!loading" class="app-modal-container">
    <div class="row" style="margin:0!important;">
        <div class="col-md-4 col-centered">
            <div class="app-modal-body">
                <div class="panel panel-primary">
                    <div class="panel-heading">Feedback</div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <dx-form [labelLocation]="'top'" #feedBForm [(formData)]="fb">
                                    <!--<dxi-item dataField="name" [label]="{text: 'Benutzername'}">
                                        <dxi-validation-rule type="required" message="'Name' wird benötigt!"></dxi-validation-rule>
                                    </dxi-item>-->
                                    <dxi-item dataField="email" [label]="{text: 'Ihre Email'}">
                                        <dxi-validation-rule type="required" message="'Email' wird benötigt!"></dxi-validation-rule>
                                    </dxi-item>
                                    <dxi-item dataField="betreff" [label]="{text: 'Betreff'}">
                                        <dxi-validation-rule type="required" message="'Betreff' wird benötigt!"></dxi-validation-rule>
                                    </dxi-item>
                                    <dxi-item dataField="beschreibung" editorType="dxTextArea" [label]="{text: 'Beschreibung'}">
                                    </dxi-item>
                                </dx-form>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="isErrorFeedback" class="col-md-12 text-center">
                                <small class="text-info">Ein Fehlerbericht wird automatisch mit gesendet.</small>
                            </div>
                        </div>
                        <div class="row" *ngIf="errMsg && errMsg.length">
                            <div class="col-md-12 text-danger text-center" [innerHtml]="sanitizeHtml(errMsg)">
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer">
                        <button type="button" style="margin-right:5px;" class="btn btn-success" (click)="onClickSend()"><span class="glyphicon glyphicon-save"> Senden</span></button>
                        <button type="button" style="margin-right:5px;" class="btn btn-danger" (click)="closeModal('modal-feedback')"><span class="glyphicon glyphicon-remove"> Abbruch</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-modal-background"></div>
