import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'AuthService';
import { AjaxService } from 'AjaxService';
import { Subscription } from 'rxjs';
import { ServerPollService, ICountsViewModel } from 'ServerPollService';


@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  userName: string;
  version: string;
  counts: ICountsViewModel = {} as ICountsViewModel;

  private sub1: Subscription;

  popupVisible: boolean = false;
  pdfSrc = '/api/Options/GetHelpPdf';

  constructor(private ajaxService: AjaxService, private authService: AuthService, private serverPollService: ServerPollService) {
    this.hidePopup = this.hidePopup.bind(this);
    this.download = this.download.bind(this);
  }

  ngOnInit(): void {
    this.version = this.authService.getVersion();

    var names = this.authService.getNames();
    this.userName = names.name + ', ' + names.vorname;

    this.sub1 = this.serverPollService.init().subscribe(response => {
      this.counts = response;
    });
  }

  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }

  onClickLogout() {
    this.serverPollService.Stop();
    this.authService.logout();
  }

  onClickHelp(event) {
    this.popupVisible = true;
  }

  onHiddenPopup(event) {
    this.popupVisible = false;
  }

  hidePopup() {
    this.popupVisible = false;
  }

  download() {
    this.ajaxService.download(this.pdfSrc, 'Hilfe-DaBea.pdf');
  }
}
