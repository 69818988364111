<div class="panel panel-default">
    <div class="panel-heading">Informationen</div>
    <div class="panel-body">
        <div class="col-md-12">
            <div *ngIf="items && items.length">
                <div *ngFor="let item of items">
                    <div class="row" style="margin-top:20px;">
                        <div class="well">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <small class="text-right">({{item.erstellt + ' - ' + item.benutzer}})</small>
                                </div>
                                <div class="col-md-12">
                                    <h3 [ngClass]="{'text-danger': item.wichtig}">{{item.bezeichnung}}</h3>
                                </div>
                                <div class="col-md-12">
                                    <span [ngClass]="{'text-danger': item.wichtig}">{{item.beschreibung}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!items || !items.length">
                <span>Keine Informationen</span>
            </div>
        </div>
    </div>
</div>
