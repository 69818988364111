import { ErrorHandler, Injectable } from '@angular/core';
import { ModalService } from 'ModalService';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpException } from 'HttpException';
import { SpinnerService } from '@services/spinner.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(private modalService: ModalService, private spinnerService: SpinnerService) {
        super();
    }

    handleError(error: any) {

        //         if (error.message.startsWith(`Uncaught (in promise): TypeError: Cannot read property 'div' of undefined
        // TypeError: Cannot read property 'div' of undefined
        //     at backtrackBeforeAllVisibleElements`)) {
        //             // https://github.com/VadimDez/ng2-pdf-viewer/issues/367
        //             console.log('ignoring error');
        //             return;
        //         }

        if (error.message.startsWith(`Uncaught (in promise): [object Undefined]`)) {
            console.log('ignoring pdf-error');
            return;
        }


        this.spinnerService.hide();
        console.log(error);
        //this.modalService.open('modal-error', error);

        if (error instanceof HttpErrorResponse) {
            error = new HttpException(error);
            this.modalService.open('modal-error', { error: error, name: error.name, message: error.message, stack: error.stack });

        }
        else if (error.promise && error.rejection && error.rejection.ngParseErrors) {

            var _msg = '';
            var _err = JSON.parse(JSON.stringify(error, this.getCircularReplacer(), 2));
            for (var i = 0; i < _err.rejection.ngParseErrors.length; i++) {
                _msg += _err.rejection.ngParseErrors[i].msg.replace(new RegExp('\n', 'g'), '<br />') + '<br /><br />';
            }

            this.modalService.open('modal-error', { error: _err, name: error.name, message: _msg, stack: error.stack });
        }
        else if (error.promise && error.rejection) {

            const _error = new HttpException(error.rejection);

            this.modalService.open('modal-error', { error: _error, name: _error.name, message: _error.message, _error: error.stack });
        }
        else if (error instanceof Error) {
            this.modalService.open('modal-error', { error: null, name: error.name, message: error.message, stack: error.stack });
        }
        else {
            this.modalService.open('modal-error', { error: error, name: error.name, message: error.message, stack: error.stack });
        }
        //}

        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        //throw error;
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };
}
