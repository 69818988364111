import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class SpinnerService {

    public loadingState = new BehaviorSubject<boolean>(true);
    private _show: boolean = true;

    timer: any;

    hide(): any {
        this._show = false;
        this.switch(false, 250);
    }

    show(): any {
        this._show = true;
        this.switch(true, 0);
    }

    switch(value: boolean, delay: number) {
        this.clear();
        this.timer = setTimeout(() => {
            this.loadingState.next(this._show);
        }, delay);
    }

    private clear() {
        clearTimeout(this.timer);
    }
}
