<div class="panel panel-default">
  <div class="panel-heading">Wiedervorlagen</div>
  <div class="panel-body" *ngIf="mitarbeiter && userId > 0">
    <div class="row">
      <div class="col-md-12">
        <dx-select-box [dataSource]="mitarbeiter" displayExpr="name" valueExpr="id"
          (onSelectionChanged)="updateViewData()" [(value)]="_userId" [showClearButton]="false" #mitarbeiterSelect>
        </dx-select-box>
      </div>
    </div>
    <div class="row" style="margin-top:20px">
      <div class="col-md-12">
        <dx-data-grid [dataSource]="items" (onRowPrepared)="handleOnRowPrepared($event)" (onRowUpdating)="save($event)">
          <dxo-filter-row [visible]="false"></dxo-filter-row>

          <dxo-editing mode="form" [allowUpdating]="allowUpdating" [useIcons]="true">
            <dxo-form [colCount]="1" [labelLocation]="'top'">
              <dxi-item itemType="group">
                <dxi-item [colSpan]="1" dataField="benutzerId"></dxi-item>
                <dxi-item [colSpan]="1" dataField="termin"></dxi-item>
                <dxi-item [colSpan]="1" dataField="grundId" editorType="dxSelectBox"
                  [editorOptions]="{items: gruende, displayExpr: 'name', valueExpr: 'id', acceptCustomValue: true}">
                </dxi-item>
                <dxi-item [colSpan]="1" dataField="betreff"></dxi-item>
              </dxi-item>
            </dxo-form>
          </dxo-editing>

          <dxi-column type="buttons">
            <dxi-button name="edit"></dxi-button>
            <dxi-button hint="Erledigt" icon="check" [visible]="isErledigtButtonVisible"
              [onClick]="remove"></dxi-button>
          </dxi-column>

          <dxi-column dataField="id" [visible]="false"></dxi-column>
          <dxi-column dataField="termin" format="dd.MM.yyyy" dataType="date"></dxi-column>
          <dxi-column dataField="bezeichnung" caption="Art"></dxi-column>
          <dxi-column dataField="betreff"></dxi-column>
          <dxi-column dataField="benutzerId" caption="Benutzer">
            <dxo-lookup [dataSource]="mitarbeiter" displayExpr="name" valueExpr="id"></dxo-lookup>
          </dxi-column>
          <dxi-column dataField="grundId" caption="Grund">
            <dxo-lookup [dataSource]="gruende" displayExpr="name" valueExpr="id"></dxo-lookup>
          </dxi-column>

          <dxi-column [width]="40" cellTemplate="linkToEinrichtungTemplate"></dxi-column>
          <div *dxTemplate="let cellInfo of 'linkToEinrichtungTemplate'">
            <button type="button" class="btn btn-primary btn-xs" (click)="onClickOpen(cellInfo.data)"><span
                class="glyphicon glyphicon-play"></span></button>
          </div>

        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
