import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AjaxService } from 'AjaxService';
import MsgReader from '@freiraum/msgreader';

@Component({
    selector: 'cz-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, OnDestroy {

    @ViewChild('imageViewver', { static: false }) imageViewver: ElementRef;

    @Input() pdfUrl: string;
    @Input() fileName: string;
    @Output() onPdfLoaded: EventEmitter<any> = new EventEmitter<any>();

    emailData: any;
    emailHeader: any;

    rendering: boolean = true;
    isPdfRdy: boolean = false;
    isImgRdy: boolean = false;
    isEmailRdy: boolean = false;
    error: string = '';

    pdfSrc = {
        url: '/api/Dokument/GetDocForVorschau?docName=',
        withCredentials: true,
        httpHeaders: this.ajaxService.getHeadersForDocViewver()
    };

    get ext(): string {
        if (this.fileName) {
            return this.getFileExtension(this.fileName);
        }

        return null;
    }

    constructor(private ajaxService: AjaxService) {
    }

    ngOnInit(): void {
        var self = this;

        if (!this.ext && this.pdfUrl) {

            var url = this.pdfUrl;
            this.pdfSrc.url = url;
            setTimeout(function() {
                self.isPdfRdy = true;
            }, 100);
        }
        else if (this.extEqual('.msg')) {

            var url = '/api/Dokument/GetDocForVorschau?docName=' + this.fileName;

            this.ajaxService.downloadResource(url).then(blop => {
                this.ajaxService.blobToArrayBuffer(blop, arrayBuffer => {
                    var tmp = new MsgReader(arrayBuffer);
                    var res = tmp.getFileData();

                    if (res.error) {
                        throw new Error(res.error);
                    }
                    else {
                        this.emailData = res;
                        if (typeof this.emailData.body === 'string') {
                            this.emailData.body = this.emailData.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                        }
                        if (this.emailData.headers) {
                            this.emailHeader = this.parseEmailHeaders(this.emailData.headers);
                        }
                        else {
                            this.emailHeader = this.parseRecipients(this.emailData);
                        }

                        this.isEmailRdy = true;
                    }
                });
            });
        }
        else if (this.extEqual('.pdf')) {
            this.pdfSrc.url = '/api/Dokument/GetDocForVorschau?docName=' + this.fileName;
            setTimeout(function() {
                self.isPdfRdy = true;
            }, 100);
        }
        else if (this.extEqual('.docx')) {
            this.pdfSrc.url = '/api/Dokument/GetWordDocForVorschau?docName=' + this.fileName;
            setTimeout(function() {
                self.isPdfRdy = true;
            }, 100);
        }
        else if (this.extEqual('.doc')) {
            this.pdfSrc.url = '/api/DokumentArchive/GetWordDocForVorschau?docName=' + this.fileName;
            setTimeout(function() {
                self.isPdfRdy = true;
            }, 100);
        }
        else if (this.extEqual('.jpeg') || this.extEqual('.jpg')) {
            var blob = this.ajaxService.downloadResource('/api/Dokument/GetDocForVorschau?docName=' + this.fileName);
            self.isImgRdy = true;
            blob.then(responseBlob => {
                var url = URL.createObjectURL(responseBlob);
                self.imageViewver.nativeElement.src = url;
                setTimeout(function() {
                    window.URL.revokeObjectURL(url);
                }, 100);
            });
        }
    }

    ngOnDestroy(): void {
        this.pdfSrc.url = '';

        var self = this;

        setTimeout(function() {
            self.isPdfRdy = false;
        }, 200);
    }

    onPdfRdyLoaded(pdf: any) {
        this.onPdfLoaded.emit(true);
    }

    onPdfPageRendered(e: any) {
        this.rendering = false;
    }

    onPdfError(error: Error) {
        if (error) {
            this.error = error.message || 'FEHLER beim laden des PDFs, möglicherweise Datei nicht mehr vorhanden!';
        } else {
            this.error = 'FEHLER beim laden des PDFs, möglicherweise Datei nicht mehr vorhanden!';
        }

        console.error(this.error, error);
    }

    parseEmailHeaders(headers) {
        var parsedHeaders = {};
        if (!headers) {
            return parsedHeaders;
        }
        var headerRegEx = /(.*)\: (.*)/g;
        var m: any;
        while (m = headerRegEx.exec(headers)) {
            // todo: Pay attention! Header can be presented many times (e.g. Received). Handle it, if needed!
            parsedHeaders[m[1]] = m[2];
        }
        return parsedHeaders;
    }

    parseRecipients(eMaildData) {
        var parsedHeaders = {};
        parsedHeaders['From'] = eMaildData.senderName;
        parsedHeaders['To'] = eMaildData.recipients[0].name + ' <' + eMaildData.recipients[0].email + '>';

        return parsedHeaders;
    }

    getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? '.' + /[^.]+$/.exec(filename)[0] : undefined;
    }

    extEqual(_ext: string) {
        return _ext.toLowerCase() === this.ext.toLowerCase();
    }
}
