import { HttpErrorResponse } from '@angular/common/http';

export class HttpException extends Error {
    //public HaveToWait: boolean;
    public waitContent: any;
    public httpErrorResponse: HttpErrorResponse;

    constructor(response: HttpErrorResponse) {

        if (response.error) {
            if (response.error.message) {
                super(response.error.message);
                this.stack = response.error.stackTrace;
            }
            else if (response.error.Message) {
                super(response.error.Message);
                this.stack = response.error.StackTrace;
            }
            else if (response.error.msg) {
                super(response.error.msg);
                this.stack = response.error.StackTrace;
            }
            else if (response.error.Msg) {
                super(response.error.Msg);
                this.stack = response.error.StackTrace;
            }
            else if ((response.error as string).length > 0) {
                super(response.error as string);
            }
            else{
                super(response.statusText);
            }

            if (response.statusText === 'Conflict') {
                if (response.error.What != null) {

                    if (response.error.What === 'wait') {
                        this.waitContent = response.error as string;
                    }
                }
            }
        }
        else if (response.message) {
            super(response.message);
            this.name = response.statusText;
        }
        else {
            super(response.statusText);
            this.name = response.statusText;
        }

        this.httpErrorResponse = response;


        Object.setPrototypeOf(this, HttpException.prototype);

    }
}
