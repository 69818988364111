

//  Common
import { NgModule, ErrorHandler, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import lcoaleDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(lcoaleDe, localeDeExtra);


// helper
import { ErrorInterceptorProvider } from './helper/error-interceptor';
import { GlobalErrorHandler } from './helper/global-error.handler';

// module
import { SharedModule } from './components/shared/SharedModule/shared.module';
import { AppRoutingModule, routedComponents } from './app-routing.module';
// import { DndModule } from '@beyerleinf/ngx-dnd';

// components
import { AppComponent } from './components/app/app.component';

@NgModule({
  declarations: [
    AppComponent,
    routedComponents,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    // DndModule,
  ],
  providers: [
    { provide: LOCALE_ID, useFactory: () => 'de-DE' },
    ErrorInterceptorProvider,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
]
})
export class AppModule { }
