
import { ValidationErrors, FormControl } from '@angular/forms';


export const errorMessages = {


    required: () => '##FIELD## wird benötigt',


    minlength: (params) => '##FIELD## benötigt mindestens ' + params.requiredLength + ' Zeichen',


    maxlength: (params) => '##FIELD## should not be greater then ' + params.requiredLength + ' characters',


    pattern: () => 'Nicht gültig',
    IsURL: () => 'Keine gültige URL',
    IsEmail: () => 'Keine gültige Email',
    email: () => 'Should be vaild email.',

    vaildPasswordEqual: () => 'Passwörter stimmen nicht überein',
    oneUpperCase: () => 'Mindestens ein Großbuchstabe benötigt',
    IsNumeric: () => '##FIELD## muss eine Zahl sein'
};

export function IsURL() {

    return function IsEmail(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }

        if (!control.value) {
            return true;
        }

        // pattern='https?://www\..+'
        let isValid = /^www./.test(control.value);

        var result = {};

        if (isValid) {
            result = true;
        } else {
            result = {
                IsURL: {
                    valid: false
                }
            };

            return result;
        }
    };
}

export function IsEmail() {

    return function IsEmail(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }

        if (!control.value) {
            return true;
        }

        let isValid = /^[_a-zA-Z0-9ÄÖÜäöüß\.\-]+(\.[_a-z0-9]+)*@[_a-zA-Z0-9ÄÖÜäöüß\-]+(\.[_a-zA-Z0-9ÄÖÜäöüß\-]+)*(\.[a-zA-Z]{2,4})$/.test(control.value);

        var result = {};

        if (isValid) {
            result = true;
        } else {
            result = {
                IsEmail: {
                    valid: false
                }
            };

            return result;
        }
    };
}

export function IsNumeric() {

    return function IsNumeric(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }

        if (!control.value) {
            return true;
        }

        var req = (control.value && !isNaN(control.value));


        var result = {};

        if (!req) {
            result = {
                IsNumeric: false
            };
        }
        else {
            result = true;
        }

        return result;
    };
}


export function vaildPasswordOneUpCase(checkEmptyToo: boolean) {

    return function vaildPasswordEqual(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }

        var tmp = control.value ? (control.value as string).toLowerCase() : control.value;

        if (!checkEmptyToo) {
            if (!control.value) {
                return true;
            }
        }

        var result = {};

        if (tmp === control.value) {
            result = {
                oneUpperCase: false
            };
        }
        else {
            result = true;
        }

        return result;
    };
}


export function vaildPasswordRequired(checkEmptyToo: boolean) {

    return function vaildPasswordEqual(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }


        if (!checkEmptyToo) {
            if (!control.value || (control.value as string).length === 0) {
                return true;
            }
        }

        var req = (control.value && (control.value as string).length > 0);

        var result = {};

        if (!req) {
            result = {
                required: false
            };
        }
        else {
            result = true;
        }

        return result;
    };
}


export function vaildPasswordEqual(otherControlName: string) {

    return function vaildPasswordEqual(control: FormControl): ValidationErrors | boolean {
        if (!control || !control.parent) {
            return null;
        }
        let other = control.root.get(otherControlName);

        if (!other) {
            return null;
        }


        var controlValue = control.value === undefined || control.value === null ? '' : control.value;
        var otherValue = other.value === undefined || other.value === null ? '' : other.value;

        var equeal = (controlValue === otherValue);


        var result = {};


        if (!equeal) {
            result = {
                vaildPasswordEqual: false,
            };
        }
        else {

            result = true;
        }

        return result;
    };
}
