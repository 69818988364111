import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable ,  throwError as _throw } from 'rxjs';
import { HttpException } from 'HttpException';
import { AuthService } from 'AuthService';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {

    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {

                    if (err.status === 403) {
                        this.authService.logoutWithError(err);
                        return _throw(new HttpException(err));
                    }

                    if (err.error instanceof Blob && err.error.type === 'application/json') {
                        return new Promise<any>((resolve, reject) => {
                            let reader = new FileReader();
                            reader.onload = (e: Event) => {
                                try {
                                    const errmsg = JSON.parse((<any>e.target).result);
                                    reject(new HttpErrorResponse({
                                        error: errmsg,
                                        headers: err.headers,
                                        status: err.status,
                                        statusText: err.statusText,
                                        url: err.url
                                    }));
                                } catch (e) {
                                    console.log(e);
                                    reject(err);
                                }
                            };
                            reader.onerror = (e) => {
                                console.log(e);
                                reject(err);
                            };
                            reader.readAsText(err.error);
                        });
                    }
                }
                return _throw(err);
            })
        );
    }
}

/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
