import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, timer } from 'rxjs';
import { AjaxService } from 'AjaxService';

export interface ICountsViewModel {
    traegerRegCount?: number;
    antragBeCount?: number;
    antragUeberbelegungCount?: number;
    antragAnrechnungCount?: number;
    meldungenEoECount?: number;
    meldepflichtenCount?: number;
    meldungenJaehrlichCount?: number;
    hasNewSelbstauskunft?: number;
    clientServerPollTime: number;
}

@Injectable()
export class ServerPollService implements OnDestroy {

    private isInit: boolean = false;
    private timer_subscription: Subscription;
    private clientServerPollTime: number;

    private counts = new BehaviorSubject<ICountsViewModel>({} as ICountsViewModel);

    constructor(private ajaxService: AjaxService) {
    }

    public init(): BehaviorSubject<ICountsViewModel> {
        if (!this.isInit) {
            let _timer = timer(1000, 20000); 

            this.counts = new BehaviorSubject<ICountsViewModel>({} as ICountsViewModel);

            this.timer_subscription = _timer.subscribe(t => {
                this.timer_tick();
            });

            this.isInit = true;
        }

        return this.counts; 
    }


    private timer_tick() {
        if (this.isInit) {
            this.ajaxService.get<ICountsViewModel>('api/Poll/GetCounts').subscribe(response => {
                this.counts.next(response);
                if (this.clientServerPollTime !== response.clientServerPollTime) {
                    this.timer_subscription.unsubscribe();
                    this.clientServerPollTime = response.clientServerPollTime;
                    let _timer = timer(1000, this.clientServerPollTime);

                    this.timer_subscription = _timer.subscribe(t => {
                        this.timer_tick();
                    });
                }
            }, err => {
                this.Stop();
                throw err;
                //console.log(err);
            });
        }
        else {
            this.Stop();
        }
    }

    Stop() {
        this.isInit = false;

        if (this.timer_subscription) {
            this.timer_subscription.unsubscribe();
            this.timer_subscription.closed = true;
            this.timer_subscription.remove(this.timer_subscription);
        }

        if (this.counts) {
            this.counts.complete();
        }
    }

    ngOnDestroy(): void {
        this.Stop();
    }
}
