import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'AuthService';
import { Injectable } from '@angular/core';

@Injectable()
export class ExternGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isNew() && state.url !== '/extern/profil' && state.url !== '/extern/selbstauskunft') {
      this.router.navigate(['/extern/profil']);
      return false;
    }
    else if (!this.authService.isFreigegeben() && state.url !== '/extern/selbstauskunft' && state.url !== '/extern/profil') {
      this.router.navigate(['/extern/selbstauskunft']);
      return false;
    }

    return true;
  }
}
