import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServerPollService, ICountsViewModel } from 'ServerPollService';
import { AuthService } from 'AuthService';
import { Subscription } from 'rxjs';

@Component(
    {
        selector: 'home',
        templateUrl: './home.component.html',
    })

export class HomeComponent implements OnInit, OnDestroy {

    userName: string;
    userId: number;
    private sub1: Subscription;
    counts: ICountsViewModel = {} as ICountsViewModel;

    constructor(private authService: AuthService, private serverPollService: ServerPollService) {
    }

    ngOnInit(): void {
        this.userId = this.authService.getUserId();
        var names = this.authService.getNames();
        this.userName = names.vorname + ' ' + names.name;

        this.sub1 = this.serverPollService.init().subscribe(response => {
            this.counts = response;
        });
    }

    ngOnDestroy(): void {
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
    }

    hasCounts(): boolean {
        if (
            this.counts.traegerRegCount > 0 ||
            this.counts.antragBeCount > 0 ||
            this.counts.antragUeberbelegungCount > 0 ||
            this.counts.antragAnrechnungCount > 0 ||
            this.counts.meldungenEoECount > 0 ||
            this.counts.meldepflichtenCount > 0 ||
            this.counts.meldungenJaehrlichCount > 0
        ) {
            return true;
        }

        return false;
    }
}
