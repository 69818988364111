import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from 'AuthGuard';


import { HomeComponent } from './components/home/home.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { NavMenuExternComponent } from './components/navmenu-extern/navmenu-extern.component';
import { LoginComponent } from './components/login/login.component';
import { PassRecoveryComponent } from './components/login/pass-recovery/pass-recovery.component';
import { HomeWiedervorlageComponent } from './components/home/home-wiedervorlage/home-wiedervorlage.component';
import { HomeInfoComponent } from '@components/home/home-info/home-info.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'extern', loadChildren: () => import('./components/home-extern/home-extern.module').then(m => m.HomeExternModule), canActivate: [AuthGuard], data: { extern: true, sub: true } },
    { path: 'login', component: LoginComponent },
    { path: 'passRecovery', component: PassRecoveryComponent },
    { path: 'options', loadChildren: () => import('./components/options/options.module').then(m => m.OptionsModule), canActivate: [AuthGuard] },
    { path: 'meldungen', loadChildren: () => import('./components/meldungen/meldungen.module').then(m => m.MeldungenModule), canActivate: [AuthGuard]},
    { path: 'hauptEinrichtung', loadChildren: () => import('./components/hauptEinrichtung/haupt-einrichtung.module').then(m => m.HauptEinrichtungModule), canActivate: [AuthGuard], data: { roles: ['Einrichtung_Lesen'] } },
    { path: 'traeger', loadChildren: () => import('./components/traeger/traeger.module').then(m => m.TraegerModule), canActivate: [AuthGuard], data: { roles: ['Traeger_Lesen'] } },
    { path: 'landkreise', loadChildren: () => import('./components/kataloge/landkreise/landkreise.module').then(m => m.LandkreiseModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'traegergruppen', loadChildren: () => import('./components/kataloge/traegergruppen/traegergruppen.module').then(m => m.TraegerGruppenModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'eoeKatalog', loadChildren: () => import('./components/kataloge/eoe-katalog/eoe-katalog.module').then(m => m.EoEKatalogModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'rechtsform', loadChildren: () => import('./components/kataloge/rechtsform/rechtsform.module').then(m => m.RechtsformModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'verwaltungsgerichte', loadChildren: () => import('./components/kataloge/verwaltungsgericht/verwaltungsgericht.module').then(m => m.VerwaltungsgerichtModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'ausbildung', loadChildren: () => import('./components/kataloge/ausbildung/ausbildung.module').then(m => m.AusbildungModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'taetigkeit', loadChildren: () => import('./components/kataloge/taetigkeit/taetigkeit.module').then(m => m.TaetigkeitModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'angebotsGruppen', loadChildren: () => import('./components/kataloge/angebotsGruppen/angebotsGruppen.module').then(m => m.AngebotsGruppenModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'verbaende', loadChildren: () => import('./components/kataloge/verbaende/verbaende.module').then(m => m.VerbandModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'kontaktTypen', loadChildren: () => import('./components/kataloge/kontaktTypen/kontaktTypen.module').then(m => m.KontaktTypenModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'gruende', loadChildren: () => import('./components/kataloge/wiedervorlageGruende/wiedervorlageGruende.module').then(m => m.WiedervorlageGruendeModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    // { path: 'rechtsGrundlagenUnterbringung', loadChildren: () => import('./components/kataloge/rechtsGrundlagenUnterbringung/rechtsGrundlagenUnterbringung.module').then(m => m.RechtsGrundlagenUnterbringungModule), canActivate: [AuthGuard], data: { roles: ['Katalog_Lesen'] } },
    { path: 'traegerRegister', loadChildren: () => import('./components/traeger-register/traeger-register.module').then(m => m.TraegerRegisterModule) },
    { path: 'traegerRegisterBearbeiten', loadChildren: () => import('./components/traeger-register-admin/traeger-register-admin.module').then(m => m.TraegerRegisterAdminModule), canActivate: [AuthGuard], data: { roles: ['Traeger_Registrierung_Lesen'] } },
    { path: 'antragBe', loadChildren: () => import('./components/antragBE-intern/antragBE-intern.module').then(m => m.AntragBeInternModule), canActivate: [AuthGuard], data: { roles: ['AntragBE_Lesen'] } },
    { path: 'recherche', loadChildren: () => import('./components/recherche/recherche.module').then(m => m.RechercheModule), canActivate: [AuthGuard] },
    { path: 'ueberbelegung', loadChildren: () => import('./components/ueberbelegung-intern/ueberbelegung-intern.module').then(m => m.UeberbelegungInternModule), canActivate: [AuthGuard], data: { roles: ['Ueberbelegung_Lesen'] } },
    { path: 'anrechnung', loadChildren: () => import('./components/anerkennung-intern/anerkennung-intern.module').then(m => m.AnerkennungInternModule), canActivate: [AuthGuard], data: { roles: ['Anrechnung_Lesen'] } },
    { path: 'selbstauskunft', loadChildren: () => import('./components/selbstauskunft-intern/selbstauskunft-intern.module').then(m => m.SelbstauskunftInternModule), canActivate: [AuthGuard], data: { roles: ['Traeger_Registrierung_Lesen'] } },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [
    ]
})
export class AppRoutingModule { }

export const routedComponents = [NavMenuComponent, NavMenuExternComponent, HomeComponent, LoginComponent, PassRecoveryComponent, HomeWiedervorlageComponent, HomeInfoComponent];

