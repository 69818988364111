import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, Route } from '@angular/router';
import { Roles } from 'Roles';
import { AuthService } from 'AuthService';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isAuth()) {

      if (this.authService.isExtern()) {

        if (!this.canExtern(route)) {
          return false;
        }
        else {
          if (this.authService.isSub()) {

            if (!this.canSub(route)) {

              return false;
            }
            else{
              return true;
            }

          }
          else{
            return true;
          }
        }

      }
      else if (this.authService.hasOneRole(this.getNeededRoles(route))) {
        return true;
      }
      else {
        return false;
      }
    }

    this.router.navigate(['/login']);
    return false;
  }

  private canExtern(route: ActivatedRouteSnapshot | Route): boolean {
    var canExtern = route.data['extern'];
    if (!canExtern) {
      return false;
    }

    return canExtern === true;
  }

  private canSub(route: ActivatedRouteSnapshot | Route): boolean {
    var canSub = route.data['sub'];
    if (!canSub) {
      return false;
    }

    return canSub === true;
  }

  private getNeededRoles(route: ActivatedRouteSnapshot | Route): Roles[] {
    let _roles: Array<Roles> = [];
    if (!route.data['roles']) {
      return _roles;
    }

    for (var i = 0; i < route.data['roles'].length; i++) {
      var r = route.data['roles'][i] as string;
      var role = Roles[r] as Roles;
      if (role) {
        _roles.push(role);
      }
      else {
        throw new Error('Konnte \'' + r + '\' keiner vorhandenen Zugriffsbeschränkung zuordnen!');
      }
    }

    return _roles;
  }
}
