import { Component, OnInit, ViewChild } from '@angular/core';
import { AjaxService } from 'AjaxService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { SpinnerService } from '@services/spinner.service';

@Component({
    selector: 'cz-pass-recovery',
    templateUrl: './pass-recovery.component.html',
    styleUrls: ['./pass-recovery.component.css']
})

export class PassRecoveryComponent implements OnInit {
    @ViewChild('recaptcha', { static: false }) rechaptaComp: RecaptchaComponent;
    public form: FormGroup;
    isCaptchResolved: boolean = false;
    errMsg: string = '';
    loading: boolean = false;

    constructor(private formBuilder: FormBuilder, private spinnerService: SpinnerService, private ajaxService: AjaxService, private router: Router) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            username: ['', [Validators.required]],
            email: ['', [Validators.required]]
        });
    }

    onSubmit() {
        Object.entries(this.form.controls).forEach(([key, control]) => {
            control.markAsTouched();
        });

        if (!this.isCaptchResolved) {
            this.errMsg = 'Sind Sie ein Mensch?';

            return;
        }


        if (!this.form.invalid) {
            this.startLoading();
            this.ajaxService.post('api/Account/PasswordRecovery', this.form.value).subscribe(() => {
                this.stopLoading();
                notify({
                    message: 'Es wird ein neues Passwort an Ihre Email-Adresse gesendet.',
                    width: 600
                }, 'success', 3000);
                setTimeout(() => {
                    this.router.navigate(['/login']);
                }, 3000);
            }, err => {
                this.stopLoading();
                this.isCaptchResolved = false;
                if (this.rechaptaComp) {
                    this.rechaptaComp.reset();
                }

                setTimeout(() => {
                    throw err;
                }, 0);
            });
        }
    }

    onClickAbbruch() {
        this.router.navigate(['/login']);
    }

    onKeyupEnter() {
        this.onSubmit();
    }

    resolved(captchaResponse: string) {
        this.isCaptchResolved = true;
    }

    private startLoading() {
        this.spinnerService.show();
        this.loading = true;
    }

    private stopLoading() {
        this.spinnerService.hide();
        this.loading = false;
    }
}
