import { Component, ElementRef, Input, OnInit, OnDestroy, ApplicationRef, ChangeDetectorRef  } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalService } from 'ModalService';
import * as $ from 'jquery';
import { Subject ,  Observable } from 'rxjs';


@Component({
    moduleId: module.id.toString(),
    selector: 'app-dialogJaNein-modal',
    //template: '<ng-content></ng-content>'
    templateUrl: 'modal-dialogJaNein.component.html',
    styleUrls: ['modal-dialogJaNein.component.css']
})

export class ModalDialogJaNeinComponent implements OnInit, OnDestroy {
    @Input() id: string;

    msg: string;
    private element: JQuery<HTMLElement>;
    private sub: Subject<boolean> = new Subject<boolean>();

    constructor(private modalService: ModalService, el: ElementRef, private _sanitizer: DomSanitizer, private applicationRef: ApplicationRef, private cdr: ChangeDetectorRef) {
        this.element = $(el.nativeElement);
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        this.element.appendTo('body');

        // close modal on background click
        //this.element.on('click', function(e: any) {
        //    var target = $(e.target);
        //    if (!target.closest('.app-modal-body').length) {
        //        modal.close();
        //    }
        //});


        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(msg: string): Observable<boolean> {

        this.msg = msg;

        this.element.show();

        this.cdr.detectChanges();

        $('body').addClass('modal-open');

        return this.sub.asObservable();
    }

    // close modal
    close(): void {
        this.element.hide();
        $('body').removeClass('modal-open');
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    sanitizeHtml(html): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    onClickJa() {
        this.sub.next(true);
        this.closeModal('modal-dialogJaNein');
    }

    onClickNein() {
        this.sub.next(false);
        this.closeModal('modal-dialogJaNein');
    }
}
