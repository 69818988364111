import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'AuthService';
import { AjaxService } from 'AjaxService';
import {
  Router,
  Event as RouterEvent,
} from '@angular/router';

@Component({
  selector: 'nav-menu-extern',
  templateUrl: './navmenu-extern.component.html',
  styleUrls: ['./navmenu-extern.component.css']
})
export class NavMenuExternComponent implements OnInit, OnDestroy {
  userName: string;
  popupVisible: boolean = false;
  pdfSrc = '/api/TraegerExtern/GetDatenschutzPDF';
  popupName = 'Datenschutzerklärung';
  fileName = 'Datenschutzerklärung.pdf';


  constructor(private ajaxService: AjaxService, public authService: AuthService, private router: Router) {
    this.hidePopup = this.hidePopup.bind(this);
    this.download = this.download.bind(this);
  }


  ngOnInit(): void {
    var names = this.authService.getNames();
    this.userName = names.name + ', ' + names.vorname;
  }

  ngOnDestroy(): void {

  }

  onClickDatenschutzerklaerung(event) {
    this.fileName = 'Datenschutzerklärung.pdf';
    this.popupName = 'Datenschutzerklärung';
    this.pdfSrc = '/api/TraegerExtern/GetDatenschutzPDF';
    this.popupVisible = true;
  }

  onClickHelp(event) {
    this.fileName = 'Hilfe-DaBea.pdf';
    this.popupName = 'Hilfe';
    this.pdfSrc = '/api/TraegerExtern/GetHelpExternPdf';
    this.popupVisible = true;
  }

  hidePopup() {
    this.popupVisible = false;
  }

  download() {
    this.ajaxService.download(this.pdfSrc, this.fileName);
  }

  onHiddenPopup(event) {
    this.popupVisible = false;
  }

  onClickLogout() {
    this.authService.logout();
  }
}
