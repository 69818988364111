
import { Component, OnInit, OnDestroy, isDevMode, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'AuthService';
import { Observable, Subscription, timer } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component(
  {
    selector: 'login',
    templateUrl: './login.component.html',
  })

export class LoginComponent implements OnInit, OnDestroy {


  @ViewChild('recaptcha', { static: false }) rechaptaComp: RecaptchaComponent;

  public form: FormGroup;
  _errMsg: string = '';

  //private timer_subject = new Subject<number>();
  private timer_subscription: Subscription;
  private timer: Observable<number>;
  secondsToWait: number = 0;
  showCaptcha: boolean = false;
  isCaptchResolved: boolean = false;

  get errMsg(): string {
    if (this._errMsg) {
      return this._errMsg.replace('{n}', this.secondsToWait.toString());
    }

    return '';
  }

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit() {

    this.timer = timer(1000, 1000);

    setTimeout(() => this.authService.logout(), 0);

    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.stop();
  }

  stop() {
    if (this.timer_subscription) {
      this.timer_subscription.unsubscribe();
      this.timer_subscription.closed = true;
      this.timer_subscription.remove(this.timer_subscription);
    }
    //this.timer = null;
  }

  onSubmit() {
    Object.entries(this.form.controls).forEach(([key, control]) => {
      control.markAsTouched();
    });


    if (this.showCaptcha) {
      if (!this.isCaptchResolved) {
        this._errMsg = 'Sind Sie ein Mensch?';
        return;
      }
    }


    if (!this.form.invalid) {
      this.authService.login(this.form.value, () => {
        if (this.authService.isExtern()) {
          this.router.navigate(['/extern']);
        }
        else {
          this.router.navigate(['/home']);
        }
      }, err => {
        if (this.rechaptaComp) {
          this.rechaptaComp.reset();
        }
        if (err.error.What) {
          if (err.error.What === 'wait') {
            this.isCaptchResolved = false;
            this.showCaptcha = true;
            this.secondsToWait = err.error.Seconds;
            this._errMsg = 'Bitte {n} Sekunden warten!';
            this.stop();
            this.timer_subscription = this.timer.subscribe(t => {
              this.doTimerWork();
            });

          }
        }
        else {
          this._errMsg = err.error.Message;
        }
      });
    }
  }

  private doTimerWork() {
    if (this.secondsToWait > 0) {
      this.secondsToWait--;
    }
    else {
      this.secondsToWait = 0;
      this._errMsg = '';
      this.stop();
    }
  }

  resolved(captchaResponse: string) {
    this.isCaptchResolved = true;
  }

  onKeyupEnter() {
    this.onSubmit();
  }

  onTraegerRegisterClick() {
    this.router.navigate(['traegerRegister']);
  }

  onPasswordRecoveryClick() {
    this.router.navigate(['passRecovery']);
  }
}
